import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './auth/auth-guard.service';
import { DashboardComponent } from './dashboard/dashboard.component';
import { QuerySearchComponent } from './query-search/query-search.component';
import { QueryComponent } from './query/query.component';
import { environment } from 'src/environments/environment';
import { DocumentsComponent } from './documents/documents.component';
import { DocumentUpdateComponent } from './document-update/document-update.component';
import { ProfileComponent } from './profile/profile.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { FeedbackSearchComponent } from './feedback-search/feedback-search.component';
import { AppointmentComponent } from './appointment/appointment.component';
import { ListAppointmentsComponent } from './list-appointments/list-appointments.component';
import { TimesheetSearchComponent } from './timesheet-search/timesheet-search.component';
import { TimesheetComponent } from './timesheet/timesheet.component';
import { BillingComponent } from './billing/billing.component';
import { KMComponent } from './km/km.component';
import { TutorialComponent } from './tutorial/tutorial.component';

const routes: Routes = [{
    path: '',
    redirectTo: environment.contextRoot+'/dashboard',
    pathMatch: 'full',
}, 
{ 
  path: environment.contextRoot+'/query',
  component: QueryComponent,
  canActivate: [AuthGuardService],
  data: [{edit:false,view:false}]
},
{ 
  path: environment.contextRoot+'/query/:queryId/edit',
  component: QueryComponent,
  canActivate: [AuthGuardService],
  data: [{edit:true}]
},
{ 
  path: environment.contextRoot+'/query/:queryId/view',
  component: QueryComponent,
  canActivate: [AuthGuardService],
  data: [{edit:true,view:true}]
},
{ 
  path: environment.contextRoot+'/dashboard',
  component: DashboardComponent,
  canActivate: [AuthGuardService],
  data: { roles: [] }
},
{ 
  path: environment.contextRoot+'/queries',
  component: QuerySearchComponent,
  canActivate: [AuthGuardService],
  data: { roles: [] }
},
{ 
  path: environment.contextRoot+'/documents',
  component: DocumentsComponent,
  canActivate: [AuthGuardService],
  data: { roles: [] }
},

{ 
  path: environment.contextRoot+'/documentupdate',
  component: DocumentUpdateComponent,
  canActivate: [AuthGuardService],
  data: [{edit:false} ]
},
{ 
  path: environment.contextRoot+'/profile',
  component: ProfileComponent,
  canActivate: [AuthGuardService],
  data: [ ]
},
{ 
  path: environment.contextRoot+'/changePassword',
  component: ChangePasswordComponent,
  canActivate: [AuthGuardService],
  data: [ ]
},
{ 
  path: environment.contextRoot+'/documentupdate/:documentId/edit',
  component: DocumentUpdateComponent,
  canActivate: [AuthGuardService],
  data: [{edit:true}]
},
{ 
  path: environment.contextRoot+'/documentupdate/create',
  component: DocumentUpdateComponent,
  canActivate: [AuthGuardService],
  data: [{create:true}]
},
{ 
  path: environment.contextRoot+'/documentupdate/:documentId',
  component: DocumentUpdateComponent,
  canActivate: [AuthGuardService],
  data: [{edit:false}]
},
{ 
  path: environment.contextRoot+'/feedback',
  component: FeedbackComponent,
  canActivate: [AuthGuardService],
  data: [{view:false}]
},
{ 
  path: environment.contextRoot+'/appointment',
  component: AppointmentComponent,
  canActivate: [AuthGuardService],
  data: [{view:false}]
},
{ 
  path: environment.contextRoot+'/listAppointments',
  component: ListAppointmentsComponent,
  canActivate: [AuthGuardService],
  data: [{view:false}]
},
{ 
  path: environment.contextRoot+'/feedbackSearch',
  component: FeedbackSearchComponent,
  canActivate: [AuthGuardService],
  data: []
},{ 
  path: environment.contextRoot+'/feedback/:feedbackId/view',
  component: FeedbackComponent,
  canActivate: [AuthGuardService],
  data: [{view:true}]
},{ 
  path: environment.contextRoot+'/timesheetSearch',
  component: TimesheetSearchComponent,
  canActivate: [AuthGuardService],
  data: []
},{ 
  path: environment.contextRoot+'/timesheet',
  component: TimesheetComponent,
  canActivate: [AuthGuardService],
  data: [{edit:false}]
},{ 
  path: environment.contextRoot+'/billing',
  component: BillingComponent,
  canActivate: [AuthGuardService],
  data: [{edit:true}]
},{ 
  path: environment.contextRoot+'/km',
  component: KMComponent,
  canActivate: [AuthGuardService],
  data: { roles: [] }
},{ 
  path: environment.contextRoot+'/tutorial',
  component: TutorialComponent,
  canActivate: [AuthGuardService],
  data: { roles: [] }
},{ 
  path: environment.contextRoot+'/timesheet/:timesheetId/edit',
  component: TimesheetComponent,
  canActivate: [AuthGuardService],
  data: [{edit:true}]
},


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuardService]
})
export class AppRoutingModule { }
