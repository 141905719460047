<div class="page-content">
    <div class="page-title">
        <div class="title_left">
            <h3>Timesheet</h3>
        </div>
        <div class="title_right">
            <div class="col-md-5 col-sm-5 col-xs-12 form-group pull-right top_search">
              <form  (ngSubmit)="getSearchResult()">  
              <div class="input-group">
                    <input type="text" class="form-control" [(ngModel)]="searchCriteria.documentName" name="documentName" placeholder="Search...">
                    <span class="input-group-btn">
            <button  class="btn btn-default submit-btn" type="Submit">Go!</button>
          </span>
                </div>
              </form>
            </div>
        </div>
    </div>
    <div class="clearfix"></div>
    <div class="row">
      <div class="col-md-12">
        <div class="x_panel">
          <div class="x_title">
            <h2>Timesheet</h2>
            <a routerLink="/{{contextRoot}}/timesheet" routerLinkActive="active" class="btn btn-default submit-btn pull-right"> <i class="fa fa-plus"></i> Add Time</a>
            <div class="clearfix"></div>
          </div>
          <div class="x_content">
          <!-- start project list -->
          <div class="table-responsive">
          <table class="table table-striped projects table-res"  matSort (matSortChange)="sortData($event)">
            <thead>
              <tr>
                <th mat-sort-header="date">Date</th>
                <th mat-sort-header="query">Query</th>
                <th mat-sort-header="clientId" *ngIf="isSmithAdmin">Organization</th>
                <th mat-sort-header="startTime" >Start Time</th>
                <th mat-sort-header="endTime" >End Time</th>
                <th mat-sort-header="hours">Hours</th>
                <th class="w20">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr  *ngFor="let timesheet of sortedData">
                <td>{{timesheet.date | date:'dd-MMM-yyyy'}}</td>
                <td>{{timesheet.query}}</td>
                <td *ngIf="isSmithAdmin">{{clientMap.get(timesheet.clientId)}}</td>
                <td>{{timesheet.startTime}}</td>
                <td>{{timesheet.endTime}}</td>
                <td>{{timesheet.hours}}</td>
                <td>
                  <a  (click)="editTimesheet(timesheet.id)" class="btn btn-info btn-xs"><i class="fa fa-pencil"></i> Edit </a>
                  <a  (click)="deleteTimesheet(timesheet.id)" class="btn btn-danger btn-xs"><i class="fa fa-trash-o"></i> Delete </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <jw-pagination [items]="sortedData" (changePage)="onChangePage($event)"></jw-pagination>
          <!-- end project list -->
          </div>
        </div>
      </div>
    </div>
  </div>