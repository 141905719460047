import { KeycloakService } from 'keycloak-angular';
import {  environment } from 'src/environments/environment';
import { CommonService } from './service/common.service';
import { HttpClient } from '@angular/common/http';
 
export function initializer(keycloak: KeycloakService,http: HttpClient): () => Promise<any> {
    return (): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        const { keycloakConfig } = environment;
        try {
          await keycloak.init({
            config: keycloakConfig,
            initOptions: {
              onLoad: 'login-required',
              checkLoginIframe: false
            },
            bearerExcludedUrls: []
          }).then(async () =>    
            environment.clientCode = await http.get(environment.projectUrl+'abc/client/getClientCode',
            {headers: { 'Authorization': 'Bearer ' + keycloak.getToken(),}}).toPromise().then((data:any) =>{
              return data.object;
              })
            );
           
          
          resolve();
        } catch (error) {
          reject(error);
        }
      });
    };
  }
  