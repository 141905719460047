<div class="page-content">
    <div class="page-title">
      <div class="title_left">
        <h3 *ngIf="isView">View Query</h3>
        <h3 *ngIf="!isView && !isEdit">Create Query</h3>
        <h3 *ngIf="!isView && isEdit">Edit Query</h3>
      </div>
    </div>
    <div class="clearfix"></div>
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="x_panel">
          <div class="x_content">
              <form [formGroup]="queryForm" (ngSubmit)="saveQuery(queryForm.value)" class="form-horizontal form-label-left" >
              
                
                
                    <div class="form-group col-md-12 col-xs-12">
                      <label class="control-label col-md-2 col-sm-3 col-xs-12">Requirements*</label>
                      <div class="col-md-9 col-sm-9 col-xs-12">
                          <input type="text" class="form-control" id="subject" formControlName="subject" placeholder="Subject" [ngClass]="{ 'is-invalid': submitted &&  queryForm.controls.subject.errors }" [attr.disabled]="isView || (queryForm.value.status != '' && queryForm.value.status != 'Draft') ?'':null">
                          <div *ngIf="(queryForm.controls.subject.touched && queryForm.controls.subject.invalid) || (submitted && queryForm.controls.subject.errors)" class="red">
                            Requirements is required.
                            </div>  
                      </div>
                    </div>
                    <div *ngIf="queryForm.value.internalAssignee == userDetails.id || isSmithAdmin" class="form-group col-md-6 col-xs-12">
                        <label class="control-label col-md-4 col-sm-3 col-xs-12">Organization*</label>
                        <div class="col-md-8 col-sm-9 col-xs-12">
                            <select class="form-control" formControlName="clientId" name="clientId" [ngClass]="{ 'is-invalid': submitted &&  queryForm.controls.clientId.errors }" [attr.disabled]="isView || isEdit ?'':null">
                                <option [value]="''">Choose option</option>
                                <option *ngFor="let client of clients"  [value]="client.id">{{client.name}}</option>
                            </select>
                            <!-- <input type="text" class="form-control" id="clientId" formControlName="clientId" placeholder="Organization" > -->
                            <div *ngIf="(queryForm.controls.clientId.touched && queryForm.controls.clientId.invalid) || (submitted && queryForm.controls.clientId.errors)" class="red">
                                Organization is required.
                              </div>  
                        </div>
                    </div> 
                  <div class="form-group col-md-6 col-xs-12">
                      <label class="control-label col-md-4 col-sm-3 col-xs-12">Document Category</label>
                      <div class="col-md-8 col-sm-9 col-xs-12">
                          <select class="form-control" formControlName="documentCategory" name="documentCategory" (change)="getSubCategories()" [attr.disabled]="isView || (queryForm.value.status != '' && queryForm.value.status != 'Draft') ?'':null">
                              <option>Choose option</option>
                              <option *ngFor="let documentCategory of documentCategoryList"  >{{documentCategory.value}}</option>
                          </select>
                      </div>
                  </div>
                  <div *ngIf="queryForm.value.documentCategory" class="form-group col-md-6 col-xs-12">
                      <label class="control-label col-md-4 col-sm-3 col-xs-12">Sub Category</label>
                      <div class="col-md-8 col-sm-9 col-xs-12">
                          <select class="form-control" formControlName="subCategory" [attr.disabled]="isView || (queryForm.value.status != '' && queryForm.value.status != 'Draft') ?'':null">
                              <option>Choose option</option>
                              <option *ngFor="let subCategory of subCategories" [selected]="subCategory.value == queryForm.value.subCategory">{{subCategory.value}}</option>
                              
                          </select>
                      </div>
                  </div>
                
                
                    
                    <!-- <div class="form-group col-md-6 col-xs-12">
                        <label class="control-label col-md-3 col-sm-3 col-xs-12">Ticket Type</label>
                        <div class="col-md-9 col-sm-9 col-xs-12">
                            <select class="form-control" formControlName="ticketType" [attr.disabled]="isView || isEdit ?'':null">
                                <option>Choose option</option>
                                <option *ngFor="let ticketType of ticketTypes">{{ticketType.value}}</option>
                            </select>
                        </div>
                    </div> -->
                 
                 
                      <div [appPermission]="'QUERY_ASSIGNEE'" class="form-group col-md-6 col-xs-12">
                          <label class="control-label col-md-4 col-sm-3 col-xs-12">Assignee</label>
                          <div class="col-md-8 col-sm-9 col-xs-12">
                              <select class="form-control"  formControlName="assignee" [attr.disabled]="isView?'':null">
                                  <option>Choose option</option>
                                  <option *ngFor="let assignee of assignees" [value]="assignee.id">{{assignee.firstName}} {{assignee.lastName}}</option>
                              </select>
                          </div>
                      </div>
                      <div [appPermission]="'QUERY_ASSIGNEE'" class="form-group col-md-6 col-xs-12">
                        <label class="control-label col-md-4 col-sm-3 col-xs-12">Internal Assignee</label>
                        <div class="col-md-8 col-sm-9 col-xs-12">
                            <select class="form-control"  formControlName="internalAssignee" [attr.disabled]="isView?'':null">
                                <option>Choose option</option>
                                <option *ngFor="let internalAssignee of internalAssignees" [value]="internalAssignee[0]">{{internalAssignee[6]}} </option>
                            </select>
                        </div>
                    </div>
                      <div [appPermission]="'QUERY_ACTUAL_EFFORT'" class="form-group col-md-6 col-xs-12">
                          <label class="control-label col-md-4 col-sm-3 col-xs-12">Acutal Effort(HH:MM)</label>
                          <div class="col-md-8 col-sm-9 col-xs-12">
                              <input type="text" class="form-control"  id="actualEffort" type="text" appDurationPicker formControlName="actualEffort" placeholder="Acutal Effort" [attr.disabled]="isView?'':null">
                          </div>
                      </div>
                 
                 
                      <div  class="form-group col-md-6 col-xs-12">
                          <label class="control-label col-md-4 col-sm-3 col-xs-12">Billed Effort(HH:MM)</label>
                          <div class="col-md-8 col-sm-9 col-xs-12">
                              <input type="text" class="form-control" id="billedEffort" appDurationPicker placeholder="Billed Effort" type="text" formControlName="billedEffort" [attr.disabled]="isSmithAdmin && !isView ? null:''">
                          </div>
                      </div>

                      <div  class="form-group col-md-6 col-xs-12">
                        <label class="control-label col-md-4 col-sm-3 col-xs-12">Billed Date</label>
                        <div class="col-md-8 col-sm-9 col-xs-12">
                            <input placeholder="billedDate" [readonly]="etdDisable" formControlName="billedDate" [owlDateTime]="bdate" [owlDateTimeTrigger]="bdate" id="billedDate" name="billedDate" class="date-picker form-control col-md-7 col-xs-12"  >
                            <owl-date-time pickerType="calendar" [disabled]="(isSmithAdmin && !isView )? null:''" #bdate></owl-date-time>
                          </div>
                    </div> 

                      <div    class="form-group col-md-6 col-xs-12">
                          <label class="control-label col-md-4 col-sm-3 col-xs-12">ETD Effort(HH:MM)</label>
                          <div class="col-md-8 col-sm-9 col-xs-12">
                              <input type="text" class="form-control" id="estimatedEffort" appDurationPicker placeholder="Estimated Effort" type="text" formControlName="estimatedEffort" [attr.disabled]="((queryForm.value.internalAssignee == userDetails.id || isSmithAdmin) && !isView )? null:''">
                            </div>
                      </div>

                      <div  class="form-group col-md-6 col-xs-12">
                          <label class="control-label col-md-4 col-sm-3 col-xs-12">ETD</label>
                          <div class="col-md-8 col-sm-9 col-xs-12">
                              <!-- <input type="text" class="form-control" placeholder="ETD" type="text" formControlName="etd" > -->
                              <input placeholder="ETD" [readonly]="etdDisable" formControlName="etd" [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" id="etd" name="etd" class="date-picker form-control col-md-7 col-xs-12"  >
                              <owl-date-time pickerType="calendar"  [disabled]="((queryForm.value.internalAssignee == userDetails.id || isSmithAdmin) && !isView )? null:''"  #dt1></owl-date-time>
                            </div>
                      </div>                  
                      <div class="form-group col-md-6 col-xs-12">
                          <label class="control-label col-md-4 col-sm-3 col-xs-12">Tags</label>
                          <div class="col-md-8 col-sm-9 col-xs-12">
                            <form >
                                <input placeholder="Tags (Press Enter)" id="tags_1" type="text" class="tags form-control" type="text"  (keydown.enter)="saveTag($event)" [attr.disabled]="(!isSmithAdmin && (queryForm.value.status == '' || queryForm.value.status == 'Draft') && !isView) || (isSmithAdmin && !isView) ? null:''"/>
                              <span #tag  *ngFor="let tag of tags" class="badge badge-pill badge-success">{{tag}}
                                <i (click)="removeTag(tag)" class="fa fa-times"></i>
                              </span>
                            </form>
                             
                          </div>
                      </div>

                      <div  *ngIf="isEdit" class="form-group col-md-6 col-xs-12">
                        <label class="control-label col-md-4 col-sm-3 col-xs-12">Status</label>
                        <div class="col-md-8 col-sm-9 col-xs-12">
                            <select class="form-control" formControlName="status" [attr.disabled]="isSmithAdmin && !isView ? null:''">
                                <option>Choose option</option>
                                <option  [value]="currentStatus">{{currentStatus}}</option> 
                                <option *ngFor="let nextStatus of nextStatuses">{{nextStatus.toState}}</option>
                            </select>
                        </div>
                    </div>
                    
                    <div *ngIf="isEdit" class="form-group col-md-6 col-xs-12">
                      <label class="control-label col-md-4 col-sm-3 col-xs-12">Created By</label>
                      <div class="col-md-8 col-sm-9 col-xs-12">
                          <input type="text" class="form-control" id="h" formControlName="createdByName" placeholder="createdByName"  [attr.disabled]="true">
                          
                      </div>
                    </div>

                    <div class="form-group col-md-6 col-xs-12">
                        <label class="control-label col-md-4 col-sm-3 col-xs-12">Urgent</label>
                        <div class="col-md-8 col-sm-9 col-xs-12">
                            <input type="checkbox" id="priorityCheckbox" [checked]="queryForm.value.priority" (change)="queryForm.value.priority = !queryForm.value.priority" [attr.disabled]="(!isSmithAdmin && (queryForm.value.status == '' || queryForm.value.status == 'Draft') && !isView) || (isSmithAdmin && !isView) ? null:''"  data-toggle="modal" data-target="#urgentModel"/>
                        </div>
                    </div>
                   
                    <div class="form-group col-md-12 col-xs-12">
                        <label class="control-label col-md-2 col-sm-3 col-xs-12">Description* </label>
                        <div class="form-group col-md-9 col-xs-12">
                            <textarea type="text" class="form-control" id="description" formControlName="description" placeholder="Descritption"  [ngClass]="{ 'is-invalid': submitted &&  queryForm.controls.description.errors }" [attr.disabled]="isView || (queryForm.value.status != '' && queryForm.value.status != 'Draft') ?'':null"></textarea>
                            <div *ngIf="(queryForm.controls.description.touched && queryForm.controls.description.invalid) || (submitted && queryForm.controls.description.errors)" class="red">
                              Description is required.
                              </div>
                          </div>
                        </div>


                    <div class="form-group col-md-12 col-xs-12 text-right">
                        <div class="col-md-9 col-sm-9 col-xs-12 col-md-offset-3">
                            <button type="button" routerLink="/{{contextRoot}}/queries" routerLinkActive="active" class="btn btn-primary">Cancel</button>
                            <button  type="submit"  [attr.disabled]="isView || ( !isSmithAdmin  && (queryForm.value.status != '' && queryForm.value.status != 'Draft') && queryForm.value.internalAssignee != userDetails.id ) ?'':null" class="btn btn-default submit-btn">Save</button>
                            <button  type="submit" *ngIf="queryForm.value.status == 'Draft' " (click)="OnSubmit()" class="btn btn-default submit-btn">Submit</button>
                        </div>
                    </div>
                    
                  </form>

                </div>
            </div>


            <div class="modal fade" id="urgentModel" tabindex="-1" role="dialog" aria-labelledby="urgentModelTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                      
                  <div class="modal-content">  
                    <div class="modal-header">
                        <div class="row mt-2"> 
                      <h2 class="modal-title col-md-6 text-left" id="exampleModalLongTitle">Urgent T&C</h2>
                      <button type="button" class="close  text-right" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                        </div>
                    </div>
                    <form class="form-horizontal form-label-left">
                    <div class="modal-body">
                      This work will be taken on urgent basis and time will be communicated accordingly. Please note your package would have limited number of urgent request over and addition request will be charged according to package terms. 
                    </div>           
                    <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" data-dismiss="modal"  (click)="OnNoUrgentClick()">No</button>
                      <button type="button" class="btn btn-primary" data-dismiss="modal"  (click)="OnYesUrgentClick()">Yes</button>
                    </div>
                </form>
                  </div>
                </div>
              </div>






          </div>
          </div>
         
            <div class="title_left">
              <h3 *ngIf="isEdit">Upload the relevant documents (if any)</h3>
            </div>
    
        <div class="clearfix"></div>
        <div class="row">
          <div  *ngIf="isEdit" class="col-md-12 col-sm-12 col-xs-12">
            <div class="x_panel">
              <div class="x_content">         
                        <div class="form-horizontal form-label-left"> 
                               
                       <div *ngIf="isEdit" class="form-group col-md-6 col-xs-12">
                        <label class="control-label col-md-4 col-sm-3 col-xs-12">Choose File</label>
                        <!-- <div class="col-md-6 col-sm-9 col-xs-12"> -->
                            <!-- <div class="row">       -->
                            <input style="display:none" class="inline" type="file" (change)="handleFileInput($event.target.files)" #hiddenfileinput>
                            <img src="assets/chooseFile.png" class="cursor"  width="20" height="20" alt="Choose File"  (click)="hiddenfileinput.click()" [attr.disabled]="isView?'':null">
                            <!-- <button id="file" type="button" class="btn btn-primary "  > Choose File </button> -->
                         {{filename}}
                         <button type="button" class="btn btn-primary btn-sm" *ngIf="filename" [attr.disabled]="isView?'':null" (click)="setPrivacy()" >Upload</button>
                            <!-- <button  type="button"  class="btn btn-primary dataTables_paginate" >upload file</button> -->
                        <!-- </div> -->
                        <!-- </div> -->
                        </div>
                        <div class="row"> </div>
                   
                        <div *ngIf="isEdit" >
                          <div class="form-horizontal form-label-left"> 
                            <div *ngIf="isEdit" class="form-group col-md-12 col-xs-12">
                             <label class="control-label col-md-2 col-sm-3 col-xs-12">Uploaded Files</label>
                             <div class="col-md-8 col-sm-9 col-xs-12" >
                             <table class="table table-striped projects table-res"  >
                                 <thead>
                                                <tr>
                                                 
                                                  <th>Uploaded By</th>
                                                  <th>Document Name</th>
                                                  <th *ngIf = "isSmithAdmin || queryForm.value.internalAssignee == userDetails.id">Privacy Flag</th>
                                                  <th  [appPermission]="'QUERY_DOWNLOAD_FILE'">Action</th>
                                                </tr>
                                              </thead>
                                              <tbody >
                                                <tr *ngFor="let queryfile of queryfiles">
                                                  <td>{{queryfile[5]}} {{queryfile[6]}}</td>
                                                  <td>{{queryfile[3]}}</td>
                                                  <td *ngIf = "isSmithAdmin || queryForm.value.internalAssignee == userDetails.id">{{queryfile[7]}}</td>
                                                  <td [appPermission]="'QUERY_DOWNLOAD_FILE'"> <button type="button" class="btn btn-primary btn-sm" [attr.disabled]="isView?'':null" (click)="download(queryfile[3])" >Download</button>
                                                    <button  *ngIf="queryForm.value.status == 'Draft'" type="button" class="btn btn-primary btn-sm" [attr.disabled]="isView?'':null" (click)="deletefile(queryfile[3],queryfile[0])">Delete</button></td>
                                                </tr>
                                                 </tbody>
                                 </table>
     
     
                             </div>
                                
                             </div>
                                    
                                 </div>
                                 
                                
                                
                                      
                                
                                </div>
                        



















                        <!-- <div  *ngIf="isEdit && queryfiles.length"  class="form-group col-md-12 col-xs-12">
                            <label class="control-label col-md-2 col-sm-3 col-xs-12">Files</label>
                            <div class="col-md-8 col-sm-9 col-xs-12" >
                                <div style="padding-top: 8px;" *ngFor="let queryfile of queryfiles">
                               {{queryfile[3]}} 
                               <i class="fa fa-download" (click)="download(queryfile[3])" [attr.disabled]="isView?'':null" aria-hidden="true"></i>
                               <i *ngIf="queryForm.value.status == 'Draft'" style="padding-left : 8px;" class="fa fa-trash" (click)="deletefile(queryfile.filename,queryfile.id)" [attr.disabled]="isView?'':null" aria-hidden="true"></i>
                            </div>
                            </div>
                        </div> -->
                    

                    </div>
                </div>
            </div>
            <div class="modal fade" id="setPrivacyModal" tabindex="-1" role="dialog" aria-labelledby="setPrivacyModalTitle" aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered" role="document">
                    
                <div class="modal-content">  
                  <div class="modal-header">
                      <div class="row mt-2"> 
                    <h2 class="modal-title col-md-6 text-left" id="exampleModalLongTitle">Set Privacy</h2>
                    <button type="button" class="close  text-right" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                      </div>
                  </div>
                  <form  class="form-horizontal form-label-left">
                  <div class="modal-body">
                      <div *ngIf="isSmithAdmin || queryForm.value.internalAssignee == userDetails.id" class="form-group row">
                          <label class="control-label col-md-3 col-sm-3 col-xs-12" for="Privacy">Privacy</label>
                          <div class="col-md-6 col-sm-6 col-xs-12">
                              <select class="form-control col-md-7 col-xs-12"  name="privacyFlag" [(ngModel)]="privacyFlag">
                               <option [value]="''">Choose option</option>
                               <option *ngFor="let privacy of privacyQueryFileJoinList" [value]="privacy.value">{{privacy.value}}</option>
                              </select>
                            
                            </div>
                      </div>
                  </div>
                 <div class="modal-footer">
                    <button type="button" class="btn btn-primary" (click)="upload()">Upload</button>
                  </div>
              </form>
                </div>
              </div>
            </div>






          </div>
        </div>

        <div class="title_left">
            <h3 *ngIf="isEdit">Related Appointments</h3>
          </div>
          
          <div class="clearfix"></div>
        <div class="row">
          <div  *ngIf="isEdit" class="col-md-12 col-sm-12 col-xs-12">
            <div class="x_panel">
              <div class="x_content">         
                        <div class="form-horizontal form-label-left"> 
                       <div *ngIf="isEdit && appointments.length > 0" class="form-group col-md-12 col-xs-12">
                        <label class="control-label col-md-2 col-sm-3 col-xs-12">Appointments</label>
                        <div class="col-md-8 col-sm-9 col-xs-12" >
                        <table class="table table-striped projects table-res"  >
                            <thead>
                                           <tr>
                                            
                                             <th>Title</th>
                                             <th>Start Time</th>
                                             <th>End Time</th>
                                           </tr>
                                         </thead>
                                         <tbody >
                                           <tr *ngFor="let appointment of appointments">
                                             <td>{{appointment.title}}</td>
                                             <td>{{appointment.startTime | date:'dd-MMM-yyyy HH:mm'}}</td>
                                             <td>{{appointment.endTime | date:'dd-MMM-yyyy HH:mm'}}</td>
                                           </tr>
                                            </tbody>
                            </table>


                        </div>
                           
                        </div>

                        <div *ngIf="isEdit && appointments.length == 0" class="form-group col-md-12 col-xs-12">
                            <label class="control-label col-md-3 col-sm-3 col-xs-12">Currently there are no apointments.</label>
                            </div>
                               
                            </div>
                       
                    </div>
                </div>
            </div>
          </div>
        </div>







        <div class="title_left">
            <h3 *ngIf="isEdit">Any Further Instructions</h3>
          </div>
        
        <div class="row">
          <div *ngIf="isEdit" class="col-md-12 col-sm-12 col-xs-12">
            <div class="x_panel">
              <div class="x_content">    
                    <div class="row"> </div>
                    <div class="form-horizontal form-label-left"> 
                
                  <div class="row"></div>
                  
                    
                    <div class="form-group col-md-12 col-xs-12">
                      <label class="control-label col-md-2 col-sm-3 col-xs-12">Instructions</label>
                      <div class="col-md-8 col-sm-9 col-xs-12" >
                      <table class="table table-striped projects table-res"  >
                          <thead>
                                         <tr>
                                          
                                           <th>Commented By</th>
                                           <th>Comment</th>
                                           <th>Date Time</th>
                                         </tr>
                                       </thead>
                                       <tbody >
                                         <tr *ngFor="let comment of comments">
                                           <td>{{comment.firstname}} {{comment.lastname}}</td>
                                           <td>{{comment.comment}}</td>
                                           <td>{{comment.creationDate | date:'dd-MMM-yyyy HH:mm:ss'}}</td>
                                         </tr>
                                          </tbody>
                          </table>
                      </div>
                      </div>

                    </div>
                    
                   
                        <div *ngIf="!isView" style="padding-top: 8px;" class="form-group col-md-12 col-xs-12">
                            <div class="control-label col-md-2 col-sm-3 col-xs-12 "></div>
                          <textarea  #comment class="control-label col-md-9 col-sm-3 col-xs-12" rows="1" placeholder='Instructions' [attr.disabled]="isView?'':null"></textarea>
                          <button class="btn btn-primary  pull-right"  type="button" (click)="saveComment(comment.value,'PUBLIC')"  [attr.disabled]="isView?'':null">Add</button>
                        </div>
                        
                    </div>
                  </div>
                  </div>
                </div>
                          <div class="row">
                            <div *ngIf="(isEdit && queryForm.value.internalAssignee == userDetails.id) || isSmithAdmin && isEdit" class="col-md-12 col-sm-12 col-xs-12"  [appPermission]="'QUERY_INTERNAL_COMMENTS'">
                              <div class="x_panel">
                                <div class="x_content">    

                <div class="row"> </div>    
                 <div class="form-horizontal form-label-left row"   *ngIf="isEdit" >
                
                  <div class="row"></div>

                  <div class="form-group col-md-12 col-xs-12">
                    <label class="control-label col-md-2 col-sm-3 col-xs-12">Internal Instructions </label>
                    <div class="col-md-8 col-sm-9 col-xs-12" >
                    <table class="table table-striped projects table-res"  >
                        <thead>
                                       <tr>
                                        
                                         <th>Commented By</th>
                                         <th>Comment</th>
                                         <th>Date Time</th>
                                       </tr>
                                     </thead>
                                     <tbody >
                                       <tr *ngFor="let comment of internalComments">
                                         <td>{{comment.firstname}} {{comment.lastname}}</td>
                                         <td>{{comment.comment}}</td>
                                         <td>{{comment.creationDate | date:'dd-MMM-yyyy HH:mm:ss'}}</td>
                                       </tr>
                                        </tbody>
                        </table>
                    </div>
                    </div>  
                </div>
            
                <div [appPermission]="'QUERY_INTERNAL_COMMENTS'" *ngIf="!isView" style="padding-top: 8px;" class="form-group col-md-12 col-xs-12">
                  <div class="control-label col-md-2 col-sm-3 col-xs-12 "></div>
                <textarea  #internalComment class="control-label col-md-9 col-sm-3 col-xs-12" rows="1" placeholder='Comments' [attr.disabled]="isView?'':null"></textarea>
                <button [appPermission]="'QUERY_INTERNAL_COMMENTS'" class="btn btn-primary  pull-right"  type="button" (click)="saveComment(internalComment.value,'INTERNAL')"  [attr.disabled]="isView?'':null">Add</button>
              </div>
        </div>
      </div>
      </div>
</div>
