import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../service/common.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  public contextRoot: string = environment.contextRoot;
  clientCode:string=environment.clientCode;
  queryCount:string="";
  documentCount:string="";
  feedbackCount:string="";
  appointmentCount:string="";
  constructor(private route: ActivatedRoute,private router:Router,private commonService: CommonService) {
    this.clientCode= environment.clientCode;
   }

  ngOnInit(): void {
    this.commonService.getData(true,this.clientCode+'/queryRest/count', {}).then(response =>{
      this.queryCount=response;
  });
  this.commonService.getData(true,this.clientCode+'/documentRest/count', {}).then(response =>{
    this.documentCount=response;
});
this.commonService.getData(true,this.clientCode+'/feedbackRest/count', {}).then(response =>{
  this.feedbackCount=response;
});
this.commonService.getData(true,this.clientCode+'/appointmentRest/getUpcomingAppointmentsCount', {}).then(response =>{
  this.appointmentCount=response;
});

}

}
