import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UploadfileService {
  constructor(private http: HttpClient) { }
  upload(route,file: File,filename?:string): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    formData.append('file', file, filename);
    const req = new HttpRequest('POST', environment.projectUrl+route, formData, {
      reportProgress: true,
      responseType: 'json'
    });
    return this.http.request(req);
  }
  
  getFiles(route): Observable<any> {
    return this.http.get(environment.projectUrl+route, {responseType: 'blob'});
  }
}
