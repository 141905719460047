<div class="page-content">
    <div class="page-title">
      <div class="title_left">
        <h3>Create New Appointment</h3>
      </div>
    </div>
    <div class="clearfix"></div>
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="x_panel">
          <div class="x_content">
             
                <div  class="form-group col-md-6 col-xs-12">
                    <label class="control-label col-md-3 col-sm-3 col-xs-12">Select User*</label>
                    <div class="col-md-9 col-sm-9 col-xs-12">
                        <select class="form-control" formControlName="clientId" name="clientId" (change)="onChange($event)">
                            <option [value]="''">Choose option</option>
                            <option *ngFor="let user of users"  [value]="user[0]">{{user[1]}} {{user[2]}}</option>
                        </select>
                    </div>
                </div> 

                  <div class="row"></div>
              <div *ngIf="showCalendar">       
            <mat-progress-spinner 
    *ngIf="!events"
    color="primary"
    mode="indeterminate"
    class="mx-auto mt-5">
</mat-progress-spinner>

<div class="calendar-container" *ngIf="events">
    <div class="row mt-4">
        <div class="col-md-4 text-left">
            <!--<button type="button" routerLink="add" class="btn btn-light m-1" *ngIf="isAdmin">
                Aggiungi
            </button>-->
            <!--<div class="btn-group">
                <button type="button" class="btn btn-primary"
                    (click)="changeView('month')"
                    [class.active]="view === 'month'">
                    Mese
                </button>
                <button type="button" class="btn btn-primary"
                    (click)="changeView('week')"
                    [class.active]="view === 'week'">
                    Settimana
                </button>
                <button type="button" class="btn btn-primary"
                    (click)="changeView('day')"
                    [class.active]="view === 'day'">
                    Giorno
                </button>
            </div> -->
        </div>
        <div class="col-md-4 text-center">
            <h3 class="mt-0">{{ viewDate | calendarSchedulerDate:(view + 'ViewTitle'):locale:weekStartsOn:excludeDays:viewDays:startsWithToday }}</h3>
        </div>
        <div class="col-md-2 text-right">
        <button type="button" class="btn btn-primary" style="margin-top: 5px;" data-toggle="modal" data-target="#exampleModalCenter" (click)="resetAppointmentForm()">
            Add Appointment
    </button>
</div>
        <div class="col-md-2 text-right">
            <div class="btn-group m-1" role="group">
                       <button type="button" class="btn btn-primary"
                        mwlCalendarPreviousView
                        [view]="view"
                        [(viewDate)]="viewDate"
                        [daysInWeek]="viewDays"
                        [excludeDays]="excludeDays"
                        [disabled]="prevBtnDisabled"
                        (viewDateChange)="dateOrViewChanged()">
                    <span class="valign-center">
                        <i title="Precedente"
                            class="material-icons md-18">keyboard_arrow_left</i>
                    </span>
                </button>
                <button type="button" class="btn btn-light"
                        mwlCalendarToday
                        [(viewDate)]="viewDate"
                        (viewDateChange)="dateOrViewChanged()">
                    <span class="valign-center">
                        <i title="Oggi"
                            class="material-icons md-18">today</i>
                    </span>
                </button>
                <button type="button" class="btn btn-primary"
                        mwlCalendarNextView
                        [view]="view"
                        [(viewDate)]="viewDate"
                        [daysInWeek]="viewDays"
                        [excludeDays]="excludeDays"
                        [disabled]="nextBtnDisabled"
                        (viewDateChange)="dateOrViewChanged()">
                    <span class="valign-center">
                        <i title="Successivo"
                            class="material-icons md-18">keyboard_arrow_right</i>
                    </span>
                </button>
               
            </div>
        </div>
  </div>
  <br>
  <div [ngSwitch]="view">
        <calendar-scheduler-view *ngSwitchCase="CalendarView.Week"
                                [viewDate]="viewDate"
                                [events]="events"
                                [locale]="locale"
                                [viewDays]="viewDays"
                                [weekStartsOn]="weekStartsOn"
                                [excludeDays]="excludeDays"
                                [startsWithToday]="startsWithToday"
                                [hourSegments]="hourSegments"
                                [dayStartHour]="dayStartHour"
                                [dayEndHour]="dayEndHour"
                                [dayModifier]="dayModifier"
                                [hourModifier]="hourModifier"
                                [segmentModifier]="segmentModifier"
                                [eventModifier]="eventModifier"
                                [showEventActions]="true"
                                [showSegmentHour]="true"
                                [zoomEventOnHover]="true"
                                (dayHeaderClicked)="dayHeaderClicked($event.day)"
                                (hourClicked)="hourClicked($event.hour)"
                                (segmentClicked)="segmentClicked('Clicked', $event.segment)"
                                (eventClicked)="eventClicked('Clicked', $event.event)"
                                (eventTimesChanged)="eventTimesChanged($event)"
                                [refresh]="refresh">
        </calendar-scheduler-view>
    </div>
</div>
</div>
<!-- Modal -->
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
          
      <div class="modal-content">  
        <div class="modal-header">
            <div class="row mt-2"> 
          <h2 class="modal-title col-md-6 text-left" id="exampleModalLongTitle">Schedule Appointment</h2>
          <button type="button" class="close  text-right" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
            </div>
        </div>
        <form [formGroup]="appointmentForm" (ngSubmit)="addAppointment(appointmentForm.value)"  class="form-horizontal form-label-left">
        <div class="modal-body">
            <div *ngIf="isSmithAdmin" class="form-group row">
                <label class="control-label col-md-3 col-sm-3 col-xs-12" for="Organization">Organization <span class="required">*</span>
                </label>
                <div class="col-md-6 col-sm-6 col-xs-12">
                    <select class="form-control col-md-7 col-xs-12" formControlName="clientId" name="clientId" required="required" [ngClass]="{ 'is-invalid': submitted &&  appointmentForm.controls.clientId.errors }"   >
                     <option [value]="''">Choose option</option>
                        <option *ngFor="let client of clients"  [value]="client.id">{{client.name}}</option>
                    </select>
                    <div *ngIf="(appointmentForm.controls.clientId.touched && appointmentForm.controls.clientId.invalid) || (submitted && appointmentForm.controls.clientId.errors)" class="red">
                      Organization is required.
                      </div>  
                  </div>
            </div>

                <div class="form-group row">
                    <label class="control-label col-md-3 col-sm-3 col-xs-12" for="title">Agenda <span class="required">*</span>
                    </label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <input type="text"  formControlName="title" name="title" required="required" [ngClass]="{ 'is-invalid': submitted &&  appointmentForm.controls.title.errors }"   class="form-control col-md-7 col-xs-12" [attr.disabled]="isAppointmentEdit ? '':null">
                        <div *ngIf="(appointmentForm.controls.title.touched && appointmentForm.controls.title.invalid) || (submitted && appointmentForm.controls.title.errors)" class="red">
                          Agenda is required.
                          </div>  
                      </div>
                </div>
                <div class="form-group row">
                    <label class="control-label col-md-3 col-sm-3 col-xs-12" for="last-name">Start Time <span class="required">*</span>
                    </label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        
                        <input  formControlName="startTime" [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" id="startTime" name="startTime" class="date-picker form-control col-md-7 col-xs-12" [ngClass]="{ 'is-invalid': submitted &&  appointmentForm.controls.startTime.errors }" required="required" type="text" [attr.disabled]="isAppointmentEdit ? '':null">
                        <owl-date-time #dt1></owl-date-time>    
                        <div *ngIf="(appointmentForm.controls.startTime.touched && appointmentForm.controls.startTime.invalid) || (submitted && appointmentForm.controls.startTime.errors)" class="red">
                          Start Time is required.
                          </div>  
                      </div>
                </div>

                <div class="form-group row">
                    <label class="control-label col-md-3 col-sm-3 col-xs-12" for="last-name">End Time <span class="required">*</span>
                    </label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        
                        <input  formControlName="endTime" [owlDateTime]="dt2" [owlDateTimeTrigger]="dt2" id="endTime" name="endTime" class="date-picker form-control col-md-7 col-xs-12" [ngClass]="{ 'is-invalid': submitted &&  appointmentForm.controls.endTime.errors }" required="required" type="text" [attr.disabled]="isAppointmentEdit ? '':null">
                        <owl-date-time #dt2></owl-date-time>    
                        <div *ngIf="(appointmentForm.controls.endTime.touched && appointmentForm.controls.endTime.invalid) || (submitted && appointmentForm.controls.endTime.errors)" class="red">
                          End Time is required.
                          </div>  
                      </div>
                </div>

                <div class="form-group row">
                    <label class="control-label col-md-3 col-sm-3 col-xs-12" for="last-name">Description <span class="required">*</span>
                    </label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        
                        <textarea formControlName="content" name="content" required="required" [ngClass]="{ 'is-invalid': submitted &&  appointmentForm.controls.content.errors }"  class="form-control col-md-7 col-xs-12" [attr.disabled]="isAppointmentEdit ? '':null"></textarea>
                              <div *ngIf="(appointmentForm.controls.content.touched && appointmentForm.controls.content.invalid) || (submitted && appointmentForm.controls.content.errors)" class="red">
                                Description is required.
                                </div> 
                      </div>
                </div>
                
                <div class="form-group row">
                    <label class="control-label col-md-3 col-sm-3 col-xs-12" for="query">Context</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <select class="form-control col-md-7 col-xs-12" formControlName="queryId" name="queryId" [ngClass]="{ 'is-invalid': submitted &&  appointmentForm.controls.clientId.errors }"   [attr.disabled]="isAppointmentEdit ? '':null">
                         <option [value]="''">Choose option</option>
                            <option *ngFor="let query of queries"  [value]="query.id">{{query.subject}}</option>
                        </select>
                      </div>
                </div>
       
        
   

            
        </div>

        
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal" >Cancel</button>
          <button type="submit" class="btn btn-primary" [attr.disabled]="isAppointmentEdit ? '':null">Add</button>
        </div>
    </form>
      </div>
    </div>
  </div>



      
              
          </div>
        </div>
      </div>
    </div>
</div>
