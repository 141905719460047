<div class="page-content">
    <div class="page-title">
        <div class="title_left">
            <h3>Profile</h3>
        </div>
    </div>
    <div class="clearfix"></div>

    <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="x_panel">
                <div class="x_content">

                    <div class="col-md-3 col-sm-3 col-xs-12 profile_left">

                        <div class="profile_img">

                            <!-- end of image cropping -->
                            <div id="crop-avatar">
                                <!-- Current avatar -->
                                <div class="avatar-view" title="Change the avatar">
                                    <img src="assets/img.jpg" alt="Avatar">
                                </div>

                                <!-- Cropping modal -->
                                <div class="modal fade" id="avatar-modal" aria-hidden="true" aria-labelledby="avatar-modal-label" role="dialog" tabindex="-1">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <form class="avatar-form" action="crop.php" enctype="multipart/form-data" method="post">
                                                <div class="modal-header">
                                                    <button class="close" data-dismiss="modal" type="button">&times;</button>
                                                    <h4 class="modal-title" id="avatar-modal-label">Change Avatar</h4>
                                                </div>
                                                <div class="modal-body">
                                                    <div class="avatar-body">

                                                        <!-- Upload image and data -->
                                                        <div class="avatar-upload">
                                                            <input class="avatar-src" name="avatar_src" type="hidden">
                                                            <input class="avatar-data" name="avatar_data" type="hidden">
                                                            <label for="avatarInput">Local upload</label>
                                                            <input class="avatar-input" id="avatarInput" name="avatar_file" type="file">
                                                        </div>

                                                        <!-- Crop and preview -->
                                                        <div class="row">
                                                            <div class="col-md-9">
                                                                <div class="avatar-wrapper"></div>
                                                            </div>
                                                            <div class="col-md-3">
                                                                <div class="avatar-preview preview-lg"></div>
                                                                <div class="avatar-preview preview-md"></div>
                                                                <div class="avatar-preview preview-sm"></div>
                                                            </div>
                                                        </div>

                                                        <div class="row avatar-btns">
                                                            <div class="col-md-9">
                                                                <div class="btn-group">
                                                                    <button class="btn btn-primary" data-method="rotate" data-option="-90" type="button" title="Rotate -90 degrees">Rotate Left</button>
                                                                    <button class="btn btn-primary" data-method="rotate" data-option="-15" type="button">-15deg</button>
                                                                    <button class="btn btn-primary" data-method="rotate" data-option="-30" type="button">-30deg</button>
                                                                    <button class="btn btn-primary" data-method="rotate" data-option="-45" type="button">-45deg</button>
                                                                </div>
                                                                <div class="btn-group">
                                                                    <button class="btn btn-primary" data-method="rotate" data-option="90" type="button" title="Rotate 90 degrees">Rotate Right</button>
                                                                    <button class="btn btn-primary" data-method="rotate" data-option="15" type="button">15deg</button>
                                                                    <button class="btn btn-primary" data-method="rotate" data-option="30" type="button">30deg</button>
                                                                    <button class="btn btn-primary" data-method="rotate" data-option="45" type="button">45deg</button>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-3">
                                                                <button class="btn btn-primary btn-block avatar-save" type="submit">Done</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- <div class="modal-footer">
                                  <button class="btn btn-default" data-dismiss="modal" type="button">Close</button>
                                </div> -->
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <!-- /.modal -->

                                <!-- Loading state -->
                                <div class="loading" aria-label="Loading" role="img" tabindex="-1"></div>
                            </div>
                            <!-- end of image cropping -->

                        </div>
                        <h3>{{userDetail.firstName}} {{userDetail.lastName}}</h3>

                        <ul class="list-unstyled user_data">
                            <li><i class="fa fa-map-marker user-profile-icon"></i> {{userDetail.address}} 
                            </li>

                            <li>
                                <i class="fa fa-briefcase user-profile-icon"></i> {{userDetail.designation}} 
                            </li>
                        </ul>
                    </div>
                    <form [formGroup]="userForm" (ngSubmit)="updateUser(userForm.value)" class="form-horizontal form-label-left">
                    <div class="col-md-9 col-sm-9 col-xs-12">
                      <div class="form-group row">
                          <label class="control-label col-md-3 col-sm-3 col-xs-12" for="first-name">First Name <span class="required">*</span>
                          </label>
                          <div class="col-md-6 col-sm-6 col-xs-12">
                              <input type="text" formControlName="firstName" name="firstName" required="required" [ngClass]="{ 'is-invalid': submitted &&  userForm.controls.firstName.errors }"   class="form-control col-md-7 col-xs-12">
                              <div *ngIf="(userForm.controls.firstName.touched && userForm.controls.firstName.invalid) || (submitted && userForm.controls.firstName.errors)" class="red">
                                First Name is required.
                                </div>  
                            </div>
                      </div>
                      <div class="form-group row">
                          <label class="control-label col-md-3 col-sm-3 col-xs-12" for="last-name">Last Name <span class="required">*</span>
                          </label>
                          <div class="col-md-6 col-sm-6 col-xs-12">
                              <input type="text" formControlName="lastName" name="lastName" required="required" [ngClass]="{ 'is-invalid': submitted &&  userForm.controls.lastName.errors }"  class="form-control col-md-7 col-xs-12">
                              <div *ngIf="(userForm.controls.lastName.touched && userForm.controls.lastName.invalid) || (submitted && userForm.controls.lastName.errors)" class="red">
                                Last Name is required.
                                </div>  
                            </div>
                      </div>
                      <div class="form-group row">
                        <label class="control-label col-md-3 col-sm-3 col-xs-12" for="Organization">Organization <span class="required">*</span>
                        </label>
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <input type="text" formControlName="organization" name="organization" [attr.disabled]="''" class="form-control col-md-7 col-xs-12"> 
                          </div>
                    </div>
                    <div class="form-group row">
                        <label class="control-label col-md-3 col-sm-3 col-xs-12" for="Username">Username <span class="required">*</span>
                        </label>
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <input type="text" formControlName="userName" name="userName" [attr.disabled]="''" class="form-control col-md-7 col-xs-12"> 
                            
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="control-label col-md-3 col-sm-3 col-xs-12" for="Email">Email Id <span class="required">*</span>
                        </label>
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <input type="text" formControlName="email" name="email"  class="form-control col-md-7 col-xs-12"> 
                            <div *ngIf="(userForm.controls.email.invalid && userForm.controls.email.touched) || userForm.controls.email.dirty">
                                <span *ngIf="userForm.controls.email.errors?.required" class="red">Email is required</span>
                                <!--question mark(?) is a safe navigation operator-->
                                <span *ngIf="userForm.controls.email.errors?.pattern" class="red">Please provide a valid Email address</span>
                              </div>
                        </div>
                    </div>    


                      <div class="form-group row">
                          <label class="control-label col-md-3 col-sm-3 col-xs-12">Date Of Birth <span class="required">*</span>
                          </label>
                          <div class="col-md-6 col-sm-6 col-xs-12">
                              <input  formControlName="dateOfBirth" [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" id="dateOfBirth" name="dateOfBirth" class="date-picker form-control col-md-7 col-xs-12" [ngClass]="{ 'is-invalid': submitted &&  userForm.controls.dateOfBirth.errors }" required="required" type="text" >
                              <owl-date-time pickerType="calendar"  #dt1></owl-date-time>
                              <div *ngIf="(userForm.controls.dateOfBirth.touched && userForm.controls.dateOfBirth.invalid) || (submitted && userForm.controls.dateOfBirth.errors)" class="red">
                                Date Of Birth is required.
                                </div>  
                            </div>
                      </div>
                      <div class="form-group row">
                          <label class="control-label col-md-3 col-sm-3 col-xs-12" for="Designation">Designation<span class="required">*</span>
                          </label>
                          <div class="col-md-6 col-sm-6 col-xs-12">
                              <input type="text" formControlName="designation" name="designation" required="required" [ngClass]="{ 'is-invalid': submitted &&  userForm.controls.designation.errors }"  class="form-control col-md-7 col-xs-12">
                              <div *ngIf="(userForm.controls.designation.touched && userForm.controls.designation.invalid) || (submitted && userForm.controls.designation.errors)" class="red">
                                Designation is required.
                                </div> 
                            </div>
                      </div>
                      <div class="form-group row">
                          <label class="control-label col-md-3 col-sm-3 col-xs-12" for="address">Address<span class="required">*</span>
                          </label>
                          <div class="col-md-6 col-sm-6 col-xs-12">
                              <!-- <input type="text"  formControlName="address" name="address" required="required" [ngClass]="{ 'is-invalid': submitted &&  userForm.controls.address.errors }"  class="form-control col-md-7 col-xs-12"> -->
                              <textarea formControlName="address" name="address" required="required" [ngClass]="{ 'is-invalid': submitted &&  userForm.controls.address.errors }"  class="form-control col-md-7 col-xs-12"></textarea>
                              <div *ngIf="(userForm.controls.address.touched && userForm.controls.address.invalid) || (submitted && userForm.controls.address.errors)" class="red">
                                Address is required.
                                </div> 
                            </div>
                      </div>
                    </div>
                    <div class="form-group col-md-12 col-xs-12 text-right">
                        <div class="col-md-9 col-sm-9 col-xs-12 col-md-offset-3">
                            <button type="button" routerLink="/{{contextRoot}}/dashboard" routerLinkActive="active" class="btn btn-primary">Cancel</button>
                            <button type="Submit"  class="btn btn-default submit-btn">Update</button>
                        </div>
                    </div>
                </form>
                </div>
            </div>
        </div>
    </div>
</div>

