<div class="page-content">
    <div class="page-title">
        <div class="title_left">
            <h3>Feedback</h3>
        </div>
    </div>
    <div class="clearfix"></div>

    <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="x_panel">
                <div class="x_content">


                    <form [formGroup]="feedbackForm" (ngSubmit)="saveFeedback(feedbackForm.value)" class="form-horizontal form-label-left">
  
                        
                            
                            <div  *ngIf="isSmithAdmin" class="col-md-9 col-sm-9 col-xs-12">
                                <div class="form-group row">
                                    <label class="control-label col-md-3 col-sm-3 col-xs-12" for="last-name">Organization <span class="required">*</span>
                                    </label>
                                    <div class="col-md-6 col-sm-6 col-xs-12">
                                <select class="form-control" formControlName="clientId" name="clientId" [ngClass]="{ 'is-invalid': submitted &&  feedbackForm.controls.clientId.errors }" [attr.disabled]="isView?'':null">
                                    <option [value]="''">Choose option</option>
                                    <option *ngFor="let client of clients"  [value]="client.id">{{client.name}}</option>
                                </select>
                                <!-- <input type="text" class="form-control" id="clientId" formControlName="clientId" placeholder="Organization" > -->
                                <div *ngIf="(feedbackForm.controls.clientId.touched && feedbackForm.controls.clientId.invalid) || (submitted && feedbackForm.controls.clientId.errors)" class="red">
                                    Organization is required.
                                  </div>  
                                </div>
                            </div>
                        </div> 
        
                        <div class="col-md-9 col-sm-9 col-xs-12">
                      <div class="form-group row">
                          <label class="control-label col-md-3 col-sm-3 col-xs-12" for="last-name">Subject <span class="required">*</span>
                          </label>
                          <div class="col-md-6 col-sm-6 col-xs-12">
                              <input type="text"  [attr.disabled]="isView?'':null" formControlName="subject" name="subject" required="required" [ngClass]="{ 'is-invalid': submitted &&  feedbackForm.controls.subject.errors }"  class="form-control col-md-7 col-xs-12">
                              <div *ngIf="(feedbackForm.controls.subject.touched && feedbackForm.controls.subject.invalid) || (submitted && feedbackForm.controls.subject.errors)" class="red">
                                Subject is required.
                                </div>  
                            </div>
                      </div>
                      <div class="form-group row">
                          <label class="control-label col-md-3 col-sm-3 col-xs-12">Feedback Type<span class="required">*</span>
                          </label>
                          <div class="col-md-6 col-sm-6 col-xs-12">
                            <select class="form-control"  [attr.disabled]="isView?'':null" formControlName="feedbackType" >
                                <option>Choose option</option>
                                <option *ngFor="let feedbackType of feedbackTypes">{{feedbackType.value}}</option>
                            </select> <div *ngIf="(feedbackForm.controls.feedbackType.touched && feedbackForm.controls.feedbackType.invalid) || (submitted && feedbackForm.controls.feedbackType.errors)" class="red">
                                Feedback Type is required.
                                </div>  
                            </div>
                      </div>
                      
                      <div class="form-group row">
                        <label class="control-label col-md-3 col-sm-3 col-xs-12">Feedback<span class="required">*</span>
                        </label>
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <textarea  class="form-control" formControlName="feedback"  [attr.disabled]="isView?'':null" name="feedback" rows="5" placeholder='Feedback' ></textarea>
                            <div *ngIf="(feedbackForm.controls.feedback.touched && feedbackForm.controls.feedback.invalid) || (submitted && feedbackForm.controls.feedback.errors)" class="red">
                              Feedback is required.
                              </div>  
                          </div>
                    </div>
                    
                    </div>
                    <div class="form-group col-md-12 col-xs-12 text-right">
                        <div class="col-md-9 col-sm-9 col-xs-12 col-md-offset-3">
                            <button type="button" routerLink="/{{contextRoot}}/dashboard" routerLinkActive="active" class="btn btn-primary">Cancel</button>
                            <button *ngIf="!isView" type="Submit"  class="btn btn-default submit-btn">Update</button>
                        </div>
                    </div>
                </form>
                </div>
            </div>
        </div>
    </div>
</div>
