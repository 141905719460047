<!-- page content -->
<div class="page-content">
    <div class="page-title">
        <div class="title_left">
            <h3>Appointments</h3>
        </div>
        <div class="title_right">
            <div class="col-md-5 col-sm-5 col-xs-12 form-group pull-right top_search">
              <form  (ngSubmit)="getSearchResult()">  
                <div class="input-group">
                    <input type="text" class="form-control" [(ngModel)]="searchCriteria.title" name="title" placeholder="Appointment Search...">
                    <span class="input-group-btn">
            <button class="btn btn-default submit-btn" type="Submit">Go!</button>
        </span>
                </div>
                </form>
            </div>
        </div>
    </div>
    <div class="clearfix"></div>
    <div class="row">
      <div class="col-md-12">
        <div class="x_panel">
          <div class="x_title">
            <h2>Upcoming Appointments</h2>
            <a routerLink="/{{contextRoot}}/appointment"  routerLinkActive="active" class="btn btn-default submit-btn pull-right"> <i class="fa fa-plus"></i>Create Appointment</a>
            <div class="clearfix"></div>
          </div>
          <div class="x_content">
          <!-- start project list -->
          <div class="table-responsive">
          <table class="table table-striped projects table-res" matSort (matSortChange)="sortData($event)">
            <thead>
              <tr>
                <th mat-sort-header="title" class="w20">Agenda</th>
                <th mat-sort-header="clientId" *ngIf="isSmithAdmin">Organization</th>
                <th mat-sort-header="createdBy" >Created By</th>
                <th mat-sort-header="appointmentTo">Appointment To</th>
                <th mat-sort-header="startDate">Start Time</th>
                <th mat-sort-header="endDate">End Time</th>
                <th mat-sort-header="hangoutLink">Hangout Link</th>
              </tr>
            </thead>
            <tbody >
              <tr *ngFor="let appointment of sortedData">
                <td>{{appointment[1]}}</td>
                <td *ngIf="isSmithAdmin">{{appointment[7]}}</td>
                <td>{{appointment[8]}} {{appointment[9]}}</td>
                <td>{{appointment[10]}} {{appointment[11]}}</td>
                <td>{{appointment[3] | date:'dd-MMM-yyyy HH:mm'}}</td>
                <td>{{appointment[4] | date:'dd-MMM-yyyy HH:mm'}}</td>
                <td><a href="{{appointment[12]}}" target="_blank">{{appointment[12]}}</a></td>
              </tr>
               </tbody>
          </table>
        </div>
        <jw-pagination [items]="sortedData" (changePage)="onChangePage($event)"></jw-pagination>
          <!-- end project list -->
          </div>
        </div>
      </div>
    </div>
<!-- /page content -->

