<div class="page-content">
    <div class="page-title">
        <div class="title_left">
            <h3>Contract Management</h3>
        </div>
        <!-- <div class="title_right">
            <div class="col-md-5 col-sm-5 col-xs-12 form-group pull-right top_search">
              <form  (ngSubmit)="getSearchResult()">  
              <div class="input-group">
                    <input type="text" class="form-control" [(ngModel)]="searchCriteria.documentName" name="documentName" placeholder="Documents Search...">
                    <span class="input-group-btn">
            <button  class="btn btn-default submit-btn" type="Submit">Go!</button>
          </span>
                </div>
              </form>
            </div>
        </div> -->
    </div>
    <div class="clearfix"></div>
    <div class="row">
      <div class="col-md-12">
        <div class="x_panel">
          <div class="x_title">
            <h2>Contract Management</h2>
            <a (click)="createDocument()" class="btn btn-default submit-btn pull-right"> <i class="fa fa-plus"></i> Upload Contract</a>
            <div class="clearfix"></div>
          </div>
          <div class="x_content">
          <!-- start project list -->
          <div class="table-responsive">
          <table class="table table-striped projects table-res"  matSort (matSortChange)="sortData($event)">
            <thead>
              <tr>
                <th mat-sort-header="id">Document ID</th>
                <th mat-sort-header="doucmentNumber">Document Number</th>
                <th mat-sort-header="clientId" *ngIf="isSmithAdmin">Organization</th>
                <th mat-sort-header="documentName">Document Name</th>
                <th mat-sort-header="documentType">Document Type</th>
                <th mat-sort-header="documentCategory">Document Category</th>
                <th mat-sort-header="uploadedBy">Uploaded By</th>
                <th mat-sort-header="lastUpdateDate">Uploaded Date</th>
                <th class="w20">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr  *ngFor="let document of sortedData">
                <td>{{document.id}}</td>
                <td>{{document.documentNumber}}</td>
                <td *ngIf="isSmithAdmin">{{clientMap.get(document.clientId)}}</td>
                <td>{{document.documentName}}</td>
                <td>{{document.documentType}}</td>
                <td>{{document.documentCategory}}</td>
                <td>{{document.firstname}} {{document.lastname}}</td>
                <td>{{document.lastUpdateDate | date:'dd-MMM-yyyy'}}</td>
                <td>
                  <!-- <a href="images/img.jpg" class="btn btn-info btn-xs" download><i class="fa fa-download"></i></a> -->
                  <a  (click)="viewDocument(document.id)" class="btn btn-info btn-xs" ><i class="fa fa-pencil"></i> View </a>
                  
                  <a  (click)="editDocument(document.id)" class="btn btn-info btn-xs" [attr.disabled]="!(document.clientId == clientId)? '':null"><i class="fa fa-pencil"></i> Edit </a>
                  <!-- <a href="documents.html" class="btn btn-danger btn-xs"><i class="fa fa-trash-o"></i> Delete </a> -->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <jw-pagination [items]="sortedData" (changePage)="onChangePage($event)"></jw-pagination>
          <!-- end project list -->
          </div>
        </div>
      </div>
    </div>
  </div>
