import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { CommonService } from '../service/common.service';
import { GlobalService } from '../service/global.service';
import * as moment from 'moment';
declare var $: any;

@Component({
  selector: 'app-timesheet',
  templateUrl: './timesheet.component.html',
  styleUrls: ['./timesheet.component.css']
})

export class TimesheetComponent implements OnInit {
  contextRoot:string=environment.contextRoot;
  clientCode:string="";
  timesheetForm:any;
  masterData:any={};
  documentCategoryList:any=[];
  clients:any=[];
  queries:any=[];
  submitted :boolean= false;
  isEdit:boolean=false;
  disableField:boolean=false;
  isSmithAdmin:boolean=null;
  timesheetId:string="";
  message = '';
  clientId:any="";
  search2 = '';

 
  constructor(private formBuilder: FormBuilder, 
    private toastr: ToastrService,
    private commonService:CommonService,
    private route:ActivatedRoute, 
    private router: Router,
    private globalService: GlobalService) { 
      this.isEdit= route.snapshot.data[0]['edit'];
      if(this.isEdit)
     this.disableField=true;
      this.timesheetId= route.snapshot.params['timesheetId'];
      this.clientCode= environment.clientCode;
      this.getIsAdmin();
    }

    async getIsAdmin(){
      let response = await  this.globalService.callfucntion();
      this.isSmithAdmin=response[0].admin;
      if(!this.isSmithAdmin)
      {
        this.clientId=response[0].id;
        this.timesheetForm.patchValue({'clientId':response[0].id});
      }
      }

 async ngOnInit() {
   
  this.timesheetForm = this.formBuilder.group({
    'date':['',Validators.required],
    'startTime':['',Validators.required],
    'endTime':['',Validators.required],
    'hours': ['',Validators.required],
    'query':['',Validators.required],
    'activity':['',Validators.required],
    'queryId':[''],
    'clientId':[this.isSmithAdmin?'':this.clientId,Validators.required]
  });

 this.getClients();
 this.getQueries();





if(this.isEdit){
this.get(this.timesheetId);
}
  }

  selectedTempStatic(item) {
    this.search2 = item.subject;
    this.timesheetForm.patchValue({
      'queryId':item.id,
      'query': item.subject
    });
  }
  getClients()
  {
    this.commonService.getData(true,this.clientCode+'/client/?firstResult=-1&maxResult=0', {}).then(response =>{
      this.clients =response;
    })    
  }
  getQueries()
  {
    this.commonService.getData(true,this.clientCode+'/queryRest/?firstResult=-1&maxResult=0', {}).then(response =>{
      this.queries =response;
    })    
  }
  
  get(timesheetId:string){
    this.commonService.getData(true,this.clientCode+'/timesheetRest/'+timesheetId, {}).then(response =>{

      if (response.date != null){
       response.date = new Date(Number(moment(response.date).format('YYYY')),
        Number(moment(response.date).format('MM'))-1,
        Number(moment(response.date).format('DD')),
        Number(moment(response.date).format('HH')),
        Number(moment(response.date).format('mm')),);
			}

      if (response.startTime != null){
        response.startTime = new Date(new Date().getFullYear(),
        new Date().getMonth()-1,
        new Date().getDay(),
        response.startTime.split(":")[0],
        response.startTime.split(":")[1]);
       }

       if (response.endTime != null){
        response.endTime = new Date(new Date().getFullYear(),
        new Date().getMonth()-1,
        new Date().getDay(),
        response.endTime.split(":")[0],
        response.endTime.split(":")[1]);
       }



      this.timesheetForm.patchValue({
        'date': response.date,
        'startTime': response.startTime,
        'endTime':response.endTime,
        'hours':response.hours,
        'query':response.query,
        'activity':response.activity,
        'queryId':response.queryId,
      });
  })};

  saveTimesheet(timesheetData:any)
  {
    this.submitted = true;
    // stop here if form is invalid
    if (this.timesheetForm.invalid) {
      this.toastr.error('Invalid form entries','Error');
      return;
          }  

   let compareResult= this.Compare();
   
   if(!compareResult)
   return;

   timesheetData = this.timesheetForm.value;
   timesheetData.hours=  (<HTMLInputElement>document.getElementById("hours")).value;
  this.commonService.postData(true,this.clientCode+'/timesheetRest/', timesheetData).then( response => {
    if (response.success){
    this.toastr.success("Timesheet Saved Successfully", 'Success');
    this.submitted = false;
    this.timesheetForm.reset();
    this.router.navigate(['/'+environment.contextRoot+'/timesheetSearch/']);
  }
    else {
    this.toastr.error(response.messages[0].message, 'Error');
    }
    });
}

 Compare(): boolean {
  let strStartTime = new Date(this.timesheetForm.value.startTime).getHours() + ":" + new Date(this.timesheetForm.value.startTime).getMinutes();
  let strEndTime = new Date(this.timesheetForm.value.endTime).getHours() + ":" + new Date(this.timesheetForm.value.endTime).getMinutes();
 
  let hours = this.GetHoursinMilliseconds( (<HTMLInputElement>document.getElementById("hours")).value);
  this.timesheetForm.patchValue({'startTime':new Date(this.timesheetForm.value.startTime).getHours() + ":" + new Date(this.timesheetForm.value.startTime).getMinutes(),
  'endTime':new Date(this.timesheetForm.value.endTime).getHours() + ":" + new Date(this.timesheetForm.value.endTime).getMinutes()
});

  let startTime = new Date().setHours(this.GetHours(strStartTime), this.GetMinutes(strStartTime), 0);
 // let endTime = new Date(startTime)
  let endTime =  new Date().setHours(this.GetHours(strEndTime), this.GetMinutes(strEndTime), 0);
  if (startTime > endTime) {
    this.toastr.error('Start Time is greater than end time','Error');
    return false;
  }
  if (startTime == endTime) {
      this.toastr.error('Start Time equals end time','Error');
    return false;
  }


  if (startTime < endTime) {
    if (hours > (endTime-startTime)){
      this.toastr.error('Hours Should be less then or equal to the difference of end time and start time','Error');
      return false;
    }
    else{
      return true;
    }
  
  }
}

 GetHours(d) {
  var h = parseInt(d.split(':')[0]);
  if (d.split(':')[1].split(' ')[1] == "PM") {
      h = h + 12;
  }
  return h;
}

 GetMinutes(d) {
  return parseInt(d.split(':')[1].split(' ')[0]);
}

GetHoursinMilliseconds(d) {
 
    let hoursInMilliSeconds = parseInt(d.split(':')[0]) * 3600000
    let minutesInMilliSeconds =  parseInt(d.split(':')[1]) * 60 * 1000
    return hoursInMilliSeconds + minutesInMilliSeconds;
  
}

}