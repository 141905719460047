import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../service/common.service';
import { GlobalService } from '../service/global.service';
import { Sort } from '@angular/material/sort';


export interface Document {
  id: number;
  clientId: number;
  documentName: string;
  documentCategory: string;
  documenttype:string;
  uploadedBy: number;
  lastUpdateDate: number;
  documentNumber:string;
}
@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.css']
})
export class DocumentsComponent implements OnInit {
  documents:Document[];
  clients:any=[];
  pageOfItems: Array<any>;
  clientMap:Map<string,string>=new Map();
  isSmithAdmin:boolean=null;
  clientCode:string="";
  searchCriteria:any={};
  contextRoot:string=environment.contextRoot;
  sortedData: Document[]; 
  clientId:string="";
  
  constructor( private router: Router,
    private route:ActivatedRoute,
    private commonService: CommonService,
    private globalService: GlobalService
    ) { this.clientCode=environment.clientCode;
    this.getIsAdmin();
    this.commonService.getData(true,  this.clientCode+'/documentRest/searchWithQuery/?firstResult=-1&maxResult=0', {})
    .then((response:any)=>{
    this.documents= response;
    //console.log(this.documents);
    this.sortedData = this.documents.slice();
    });
    this.getClientMap();
    }

  getClientMap()
  {
    this.commonService.getData(true,this.clientCode+'/client/?firstResult=-1&maxResult=0', {}).then(response =>{
      this.clients = response;
      for(let i=0; i<this.clients.length;i++ ){
        this.clientMap.set(this.clients[i].id,this.clients[i].name)

      }
      //console.log(this.clientMap)
    })    
  }
  async getIsAdmin(){
    let response = await  this.globalService.callfucntion();
    this.isSmithAdmin=response[0].admin;
    this.clientId=response[0].id;
  }

  createDocument(){
    this.router.navigate(['/'+environment.contextRoot+'/documentupdate/create']);
 }

  editDocument(documentId:string){
     this.router.navigate(['/'+environment.contextRoot+'/documentupdate/' + documentId + '/edit']);
  }
  viewDocument(documentId:string){
    this.router.navigate(['/'+environment.contextRoot+'/documentupdate/' + documentId ]);
 }
 getSearchResult()
 {
  this.commonService.getData(true,  this.clientCode+'/documentRest/searchWithQuery/?firstResult=-1&maxResult=0', this.searchCriteria)
  .then((response:any)=>{
  this.documents= response;
  //console.log(this.documents);
  this.sortedData = this.documents.slice();
  });
 }
  ngOnInit(): void {
  }
  sortData(sort: Sort) {
    const data = this.documents.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }
  
    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'id': return compare(a.id, b.id, isAsc);
        case 'clientId': return compare(a.clientId, b.clientId, isAsc);
        case 'documentName': return compare(a.documentName, b.documentName, isAsc);
        case 'documentCategory': return compare(a.documentCategory, b.documentCategory, isAsc);
        case 'documenttype': return compare(a.documenttype, b.documenttype, isAsc);
        case 'uploadedBy': return compare(a.uploadedBy, b.uploadedBy, isAsc);
        case 'lastUpdateDate': return compare(a.lastUpdateDate, b.lastUpdateDate, isAsc);
        case 'documentNumber': return compare(a.documentNumber,b.documentNumber,isAsc);
        default: return 0;
      }
    });
  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }
  


  }
  function compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }