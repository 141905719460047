import { Injectable, OnInit } from '@angular/core';
import { CommonService } from './common.service';
import { environment } from 'src/environments/environment';
import { promise } from 'protractor';

@Injectable({
  providedIn: 'root'
})
export  class GlobalService  {
public  isAdmin:boolean;
  clientCode:string=""
  constructor(private commonService:CommonService) {
    this.clientCode =environment.clientCode 
    this.callfucntion();
    }

 async callfucntion():Promise<boolean> {
  let object:any={};
  object.code= this.clientCode;
  return  await  this.commonService.getData(true,this.clientCode+'/client/?firstResult=0&maxResult=1', object);
  }
}
