import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { CommonService } from '../service/common.service';
import { KeycloakProfile } from 'keycloak-js';
import { KeycloakService } from 'keycloak-angular';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  keyCloakuserDetail: KeycloakProfile={};
  userDetail:any={};
  contextRoot:string=environment.contextRoot;
  clientCode:string=environment.clientCode;
  userForm:any;
  submitted :boolean= false;
  dateOfBirth;

  constructor(private formBuilder:FormBuilder,
    private commonService:CommonService,
    private keycloakService:KeycloakService,
    private toastr: ToastrService) { }

  async ngOnInit(){
    this.userForm = this.formBuilder.group({
      'firstName': ['',Validators.required],
      'lastName': ['',Validators.required],
      'designation':['',Validators.required],
      'address':['',Validators.required],
      'dateOfBirth':['',Validators.required],
      'id':['',Validators.required],
      
      'active':['',Validators.required],
      'userName':['',Validators.required],
      'emailConfirmed':['',Validators.required],
      'email':new FormControl('',[
        Validators.required,
        Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      'keycloakId':['',Validators.required],
      'clientId':['',Validators.required],
      'organization':[]
    });
    this.keyCloakuserDetail = await this.keycloakService.loadUserProfile();
    let object:any ={}
    object.userName = this.keyCloakuserDetail.username;
    this.commonService.postData(true,  this.clientCode+'/users/getUserByUsername',object)
      .then((response:any)=>{
      this.userDetail= response
      if (this.userDetail.dateOfBirth != null){
        this.userDetail.dateOfBirth = new Date(Number(moment(this.userDetail.dateOfBirth).format('YYYY')),
        Number(moment(this.userDetail.dateOfBirth).format('MM'))-1,
        Number(moment(this.userDetail.dateOfBirth).format('DD')),
        Number(moment(this.userDetail.dateOfBirth).format('HH')),
        Number(moment(this.userDetail.dateOfBirth).format('mm')),);
			}
      this.userForm.patchValue({
      'firstName': response.firstName,
      'lastName': response.lastName,
      'designation':response.designation,
      'address':response.address,
      'dateOfBirth':response.dateOfBirth,
      'id':response.id,
      'active':response.active,
      'userName':response.userName,
      'emailConfirmed':response.emailConfirmed,
      'email':response.email,
      'keycloakId':response.keycloakId,
      'clientId':response.clientId,
      'organization':response.organization
       });
  });

  }

updateUser(userForm:any)
{
  this.submitted = true;
  // stop here if form is invalid
  if (this.userForm.invalid) {
    this.toastr.error('Invalid form entries','Error');
    return;
   }
   this.commonService.updateData(true,this.clientCode+'/users/', userForm).then( response=> {
    if (response.success) {
    this.toastr.success("User Updated Successfully", 'Success')
    }
    else {
    this.toastr.error(response.message, 'Error');
    }
    });
}


}
