<!-- page content -->
    <div class="page-content">
      <div class="page-title">
          <div class="title_left">
              <h3>KNITTelly</h3>
          </div>         
      </div>
      <div class="clearfix"></div>
      <div class="row">
        <div class="col-md-18">
          <div class="x_panel">
            <div class="x_title">
              <h2>KNITTelly Items</h2>
              <div class="clearfix"></div>
            </div>
            <div class="x_content">
            <!-- start project list -->
            <div class="table-responsive">
            <table class="table table-striped projects table-res" >
              <thead>
                <tr>
                  <th >KT #</th>
                  <th >Topic</th>
                  <th >Category</th>
                  <th >Expert</th>
                  <th >Link</th>
                </tr>
              </thead>
              <tbody >
                <tr>
                  <td>1</td>
                  <td>ESOP Guide</td>
                  <td>Legal</td>
                  <td>Arunabh Choudhary</td>
                  <td><button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal">View Video</button></td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Create Query</td>
                  <td>Tutorial</td>
                  <td>Tanvi M</td>
                  <td><button type="button" class="btn btn-primary" data-toggle="modal" data-target="#createQuery">View Video</button></td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Edit/Update Query</td>
                  <td>Tutorial</td>
                  <td>Tanvi M</td>
                  <td><button type="button" class="btn btn-primary" data-toggle="modal" data-target="#editQuery">View Video</button></td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>Contract Management</td>
                  <td>Tutorial</td>
                  <td>Tanvi M</td>
                  <td><button type="button" class="btn btn-primary" data-toggle="modal" data-target="#contractManagement">View Video</button></td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>Wagering Contract</td>
                  <td>Podcast</td>
                  <td>Amol Apte</td>
                  <td><button type="button" class="btn btn-primary" data-toggle="modal" data-target="#podcast1">Listen Podcast</button></td>
                </tr>
                 </tbody>
            </table>
          </div>
          <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
              <div class="modal-content">
                
                <div class="modal-body">
                  <div class="embed-responsive embed-responsive-16by9">
          <video id="demoVideo1" width="560" height="315" controls >
            <source src="https://lawsmithdev.blob.core.windows.net/kmfiles/ESOP_Guide_V.mp4" type="video/mp4">
        </video>
        </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-warning" data-dismiss="modal"
                  onclick="document.getElementById('demoVideo1').pause();">Close</button>
                </div>
              </div>
            </div>
          </div>  
          <div class="modal fade" id="createQuery" tabindex="-2" aria-labelledby="createQuery" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
              <div class="modal-content">
                
                <div class="modal-body">
                  <div class="embed-responsive embed-responsive-16by9">
          <video id="demoVideo2" width="560" height="315" controls >
            <source src="https://lawsmithdev.blob.core.windows.net/kmfiles/LK_ Create_Query_Tutorial.mp4" type="video/mp4">
        </video>
          </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-warning" data-dismiss="modal"
                  onclick="document.getElementById('demoVideo2').pause();">Close</button>
                </div>
              </div>
            </div>
          </div>


          <div class="modal fade" id="editQuery" tabindex="-3" aria-labelledby="editQuery" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
              <div class="modal-content">
                
                <div class="modal-body">
                  <div class="embed-responsive embed-responsive-16by9">
                    <video id="demoVideo3" width="560" height="315" controls >
                      <source src="https://lawsmithdev.blob.core.windows.net/kmfiles/LK_Edit_Query_Tutorial.mp4" type="video/mp4">
                  </video>
          </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-warning" data-dismiss="modal"
                  onclick="document.getElementById('demoVideo3').pause();">Close</button>
                </div>
              </div>
            </div>
          </div>


          <div class="modal fade" id="contractManagement" tabindex="-4" aria-labelledby="contractManagement" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
              <div class="modal-content">
                
                <div class="modal-body">
                  <div class="embed-responsive embed-responsive-16by9">
                    <video id="demoVideo4" width="560" height="315" controls >
                      <source src="https://lawsmithdev.blob.core.windows.net/kmfiles/LK_LegalServices_ContractManagement.mp4" type="video/mp4">
                  </video>
          </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-warning" data-dismiss="modal"
                  onclick="document.getElementById('demoVideo4').pause();">Close</button>
                </div>
              </div>
            </div>
          </div>


          <div class="modal fade" id="podcast1" tabindex="-5" aria-labelledby="podcast1" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
              <div class="modal-content">
                
                <div class="modal-body">
                  <div class="embed-responsive embed-responsive-16by9">
                    <iframe id="podcastFrame" style="border-radius:12px" 
                    src="https://open.spotify.com/embed/episode/0i6QoOF6jv03OUTStzmrLk?utm_source=generator" 
                    width="100%" height="232" frameBorder="0" allowfullscreen="" 
                    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
          </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-warning" data-dismiss="modal"
                  (click)="closeiFrame('podcastFrame')">Close</button>
                </div>
              </div>
            </div>
          </div>
            </div>
          </div>
        </div>
      </div>
      
      
  <!-- /page content -->
 
  