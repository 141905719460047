import { Component, OnInit, HostListener, Inject, LOCALE_ID } from '@angular/core';
import {
  endOfDay,
  addMonths,
  addDays,
  addHours,
  addMinutes
} from 'date-fns';
import {
  DAYS_IN_WEEK,
  SchedulerViewDay,
  SchedulerViewHour,
  SchedulerViewHourSegment,
  CalendarSchedulerEvent,
  CalendarSchedulerEventAction,
  startOfPeriod,
  endOfPeriod,
  addPeriod,
  subPeriod,
  SchedulerDateFormatter,
  SchedulerEventTimesChangedEvent,
  CalendarSchedulerEventStatus
} from 'angular-calendar-scheduler';
import {
  CalendarView,
  CalendarDateFormatter,
  DateAdapter
} from 'angular-calendar';  
import { Subject } from 'rxjs';
import { CalendarService } from '../calendar.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../service/common.service';
import { environment } from 'src/environments/environment';
import { GlobalService } from '../service/global.service';
declare var $: any;
import * as moment from 'moment';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.css'],
  providers: [{
    provide: CalendarDateFormatter,
    useClass: SchedulerDateFormatter
}]
})
export class AppointmentComponent implements OnInit {

  title: string = 'Angular Calendar Scheduler Demo';

  CalendarView = CalendarView;

  view: CalendarView = CalendarView.Week;
  viewDate: Date = new Date();
  viewDays: number = DAYS_IN_WEEK;
  refresh: Subject<any> = new Subject();
  locale: string = 'en';
  hourSegments: number = 2;
  weekStartsOn: number = 1;
  startsWithToday: boolean = false;
  activeDayIsOpen: boolean = false;
  excludeDays: number[] = []; // [0];
  dayStartHour: number = 12;
  dayEndHour: number = 19;
  appointmentForm:any;
  submitted :boolean= false;
  minDate: Date = new Date();
  maxDate: Date = endOfDay(addMonths(new Date(), 1));
  dayModifier: Function;
  hourModifier: Function;
  segmentModifier: Function;
  eventModifier: Function;
  prevBtnDisabled: boolean = false;
  nextBtnDisabled: boolean = false;
  clientCode:string=""
  appointments:any=[];
  isSmithAdmin:boolean=null;
  clients:any=[];
  clientId:any=""
  showCalendar:boolean=false;
  userId:any=null;
  users:any=[];
  queries:any=[];
  isAppointmentEdit=false;
  keyCloakuserDetails:any={};
    actions: CalendarSchedulerEventAction[] = [
        {
            when: 'enabled',
            label: '<span class="valign-center"><i class="material-icons md-18 md-red-500">cancel</i></span>',
            title: 'Delete',
            onClick: (event: CalendarSchedulerEvent): void => {
                console.log('Pressed action \'Delete\' on event ' + event.id);
                this.commonService.postData(true,this.clientCode+'/appointmentRest/deleteAppointment/'+event.id, {}).then( response => {
                    if (response.success){
                    this.toastr.success("Appointment Cancelled Successfully", 'Success');
                    this.getEvents(this.actions,this.userId)
                    .then((events: CalendarSchedulerEvent[]) => this.events = events);
                  }
                    else {
                    this.toastr.error("You are not authorized to cancel", 'Error');
                    }
                    });
            }
        },
        {
            when: 'disabled',
            label: '<span class="valign-center"><i class="material-icons md-18 md-red-500">autorenew</i></span>',
            title: 'Restore',
            onClick: (event: CalendarSchedulerEvent): void => {
                console.log('Pressed action \'Restore\' on event ' + event.id);
            }
        }
    ];
 
    events: CalendarSchedulerEvent[];
 
    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.adjustViewDays();
    }
 
    constructor(@Inject(LOCALE_ID) locale: string, private appService: CalendarService, private dateAdapter: DateAdapter,
    private formBuilder: FormBuilder, private toastr: ToastrService, private commonService: CommonService, private globalService: GlobalService,private keycloakService: KeycloakService ) {
        this.clientCode= environment.clientCode;
      this.locale = locale;

      this.dayModifier = ((day: SchedulerViewDay): void => {
          day.cssClass = this.isDateValid(day.date) ? '' : 'cal-disabled';
      }).bind(this);

      this.hourModifier = ((hour: SchedulerViewHour): void => {
          hour.cssClass = this.isHourValid(hour) ? '' : 'cal-disabled';
          hour.backgroundColor = this.isHourValid(hour) ? '' : 'grey';

      }).bind(this);

      this.segmentModifier = ((segment: SchedulerViewHourSegment): void => {
          segment.isDisabled = !this.isDateValid(segment.date);
      }).bind(this);

      this.eventModifier = ((event: CalendarSchedulerEvent): void => {
          event.isDisabled = !this.isDateValid(event.start);
      }).bind(this);

      this.adjustViewDays();
      this.dateOrViewChanged();
  }
 
  async  ngOnInit() {
        await this.getUsersForAppointment();
        this.getIsAdmin();
        this.getQueriesCreatedByMe()
        this.appointmentForm = this.formBuilder.group({
            'clientId':[this.isSmithAdmin?'':this.clientId,Validators.required],
            'title': ['',Validators.required],
            'content': ['',Validators.required],
            'startTime': ['',Validators.required],
            'endTime': ['',Validators.required],
            'queryId':['']
            });
          await  this.getClients();
      
    }
 
    adjustViewDays(): void {
        const currentWidth: number = window.innerWidth;
        if (currentWidth <= 450) {
            this.viewDays = 1;
        } else if (currentWidth <= 768) {
            this.viewDays = 3;
        } else {
            this.viewDays = DAYS_IN_WEEK;
        }
    }
 
    changeDate(date: Date): void {
        console.log('changeDate', date);
        this.viewDate = date;
        this.dateOrViewChanged();
    }
 
    changeView(view: CalendarView): void {
      console.log('changeView', view);
      this.view = view;
      this.dateOrViewChanged();
  }
 
    dateOrViewChanged(): void {
      if (this.startsWithToday) {
          this.prevBtnDisabled = !this.isDateValid(subPeriod(this.dateAdapter, CalendarView.Day/*this.view*/, this.viewDate, 1));
          this.nextBtnDisabled = !this.isDateValid(addPeriod(this.dateAdapter, CalendarView.Day/*this.view*/, this.viewDate, 1));
      } else {
          this.prevBtnDisabled = !this.isDateValid(endOfPeriod(this.dateAdapter, CalendarView.Day/*this.view*/, subPeriod(this.dateAdapter, CalendarView.Day/*this.view*/, this.viewDate, 1)));
          this.nextBtnDisabled = !this.isDateValid(startOfPeriod(this.dateAdapter, CalendarView.Day/*this.view*/, addPeriod(this.dateAdapter, CalendarView.Day/*this.view*/, this.viewDate, 1)));
      }

      if (this.viewDate.getTime()  < this.minDate.getTime() ) {
          this.changeDate(this.minDate);
      } else if (this.viewDate.getTime()  > this.maxDate.getTime() ) {
          this.changeDate(this.maxDate);
      }
  }
 
    private isDateValid(date: Date): boolean {
        return /*isToday(date) ||*/ date >= this.minDate && date <= this.maxDate;
    }

    private isHourValid(hr: SchedulerViewHour): boolean {
      let trees = new Array(12, 13, 18, 19);
      if (trees.indexOf(hr.date.getHours()) > -1){
        return true;
      }else{
        return false;
      }
    }
 
    dayHeaderClicked(day: SchedulerViewDay): void {
        console.log('dayHeaderClicked Day', day);
    }
 
    hourClicked(hour: SchedulerViewHour): void {
        console.log('hourClicked Hour', hour);
    }
 
    segmentClicked(action: string, segment: SchedulerViewHourSegment): void {
      this.isAppointmentEdit = false;
        console.log('segmentClicked Action', action);
        console.log('segmentClicked Segment', segment);
        $("#exampleModalCenter").modal('show');
        this.appointmentForm.reset();   
        this.appointmentForm.patchValue({'clientId':this.isSmithAdmin?'':this.clientId});
        this.appointmentForm.patchValue({'startTime':segment.date});
        this.appointmentForm.patchValue({'endTime':addMinutes(segment.date, 60*(1/this.hourSegments))});
    }
 
    async eventClicked(action: string, event: CalendarSchedulerEvent) {
        console.log('eventClicked Action', action);
        console.log('eventClicked Event', event);
       
        this.keyCloakuserDetails = await this.keycloakService.loadUserProfile();
        this.commonService.getData(true,this.clientCode+'/appointmentRest/'+event.id,{}).then( response =>{

          
          let object:any ={}
          object.userName = this.keyCloakuserDetails.username;
          this.commonService.postData(true,  this.clientCode+'/users/getUserByUsername',object).then(innerResponse =>{
            console.log(response.createdBy);
            if(innerResponse.id == response.createdBy)
            {
              $("#exampleModalCenter").modal('show');
              this.isAppointmentEdit = true;
              this.appointmentForm.patchValue({'title':response.title });
              if (response.startTime != null){
                response.startTime = new Date(Number(moment(response.etd).format('YYYY')),
                Number(moment(response.startTime).format('MM'))-1,
                Number(moment(response.startTime).format('DD')),
                Number(moment(response.startTime).format('HH')),
                Number(moment(response.startTime).format('mm')),);
              }
            this.appointmentForm.patchValue({'startTime':response.startTime});
            if (response.endTime != null){
              response.endTime = new Date(Number(moment(response.endTime).format('YYYY')),
              Number(moment(response.endTime).format('MM'))-1,
              Number(moment(response.endTime).format('DD')),
              Number(moment(response.endTime).format('HH')),
              Number(moment(response.endTime).format('mm')),);
            }
            this.appointmentForm.patchValue({'endTime':response.endTime});
            this.appointmentForm.patchValue({'content':response.content});
            this.appointmentForm.patchValue({'queryId':response.queryId});
            
            
            
            
            }
            
          
          });



          

        });


        
    }
 
    eventTimesChanged({ event, newStart, newEnd }: SchedulerEventTimesChangedEvent): void {
        console.log('eventTimesChanged Event', event);
        console.log('eventTimesChanged New Times', newStart, newEnd);
        let ev = this.events.find(e => e.id === event.id);
        ev.start = newStart;
        ev.end = newEnd;
        this.refresh.next();
    }
    resetAppointmentForm()
    {
        this.appointmentForm.reset(); 
        this.appointmentForm.patchValue({'clientId':this.isSmithAdmin?'':this.clientId});
    }

    addAppointment(appointmentForm:any){
        this.isAppointmentEdit = false;
        this.submitted = true;
        // stop here if form is invalid
        if (this.appointmentForm.invalid) {
          this.toastr.error('Invalid form entries','Error');
          return;
        }
        let diff =Math.abs(appointmentForm.endTime - appointmentForm.startTime) ;
        var diffMins = Math.floor((diff/1000)/60); 
        if(diffMins<30){
          this.toastr.error('Please create appointment for more than 30 Minutes.','Error');
          return;
        }
              appointmentForm.userId = this.userId;
              this.commonService.postData(true,this.clientCode+'/appointmentRest/', appointmentForm).then( response => {
                if (response.success){
                this.toastr.success("Appointment added Successfully", 'Success');
                this.submitted = false;
                $("#exampleModalCenter").modal('hide');
                this.appointmentForm.reset();
                this.getEvents(this.actions,this.userId)
                .then((events: CalendarSchedulerEvent[]) => this.events = events);
              }
                else {
                this.toastr.error(response.messages[0].message, 'Error');
                }
                });
    }

   async getEvents(actions: CalendarSchedulerEventAction[],  userId:String): Promise<CalendarSchedulerEvent[]> {
        const events = [];
        let object:any ={}
        object.userId=userId
  return  this.commonService.getData(true,this.clientCode+'/appointmentRest/?firstResult=-1&maxResult=0', object).then(response =>{
            this.appointments =response;
            
            for(let i=0; i<this.appointments.length;i++)
            {
                events.push(<CalendarSchedulerEvent>{
                    id: this.appointments[i].id,
                    start: this.appointments[i].startTime,
                    end: this.appointments[i].endTime,
                    title: 'Slot Booked',
                    title2: this.appointments[i].title,
                    clientId:this.appointments[i].clientId,
                    content:  this.appointments[i].content,
                    color: { primary: '#E0E0E0', secondary: '#EEEEEE' },
                    actions: actions,
                    status: 'danger' as CalendarSchedulerEventStatus,
                    isClickable: true,
                    isDisabled: false,
                    draggable: false,
                    resizable: {
                        beforeStart: true,
                        afterEnd: true
                    }
                });
            }
            
           
                
                
            
          
           
          
            return new Promise(resolve => setTimeout(() => resolve(events), 3000));


          });

          
        }

        async getIsAdmin(){
            let response = await  this.globalService.callfucntion();
            this.isSmithAdmin=response[0].admin;
            if(!this.isSmithAdmin)
            {
             
              this.clientId = response[0].id;
            }
          }

    async getClients()
    {
    this.commonService.getData(true,this.clientCode+'/client/?firstResult=-1&maxResult=0', {}).then(response =>{
      this.clients =response;
    })    
  }


  
  async getUsersForAppointment()
  {
  this.commonService.getData(true,this.clientCode+'/appointmentRest/getAppointmentUserList', {}).then(response =>{
    this.users =response;
  })    
}

  getQueriesCreatedByMe()
  {
    this.commonService.getData(true,  this.clientCode+'/queryRest/?firstResult=-1&maxResult=0', {})
    .then((response:any)=>{
    this.queries= response;
    });
  }

  public onChange(event: any): void {  // event will give you full breif of action
    const user = event.target.value;
    if(user!=null && user!= undefined && user!=""){  
        this.userId =user;
        this.getEvents(this.actions,user)
        .then((events: CalendarSchedulerEvent[]) => this.events = events);
        this.showCalendar=true;
        console.log(user);
    }
    else
    this.showCalendar=false;
  }


}
