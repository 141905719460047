import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../service/common.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from '../service/global.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {
  contextRoot:string=environment.contextRoot;
  clientCode:string=environment.clientCode;
  feedbackForm:any;
  submitted :boolean= false;
  feedbackTypes: any=[];
  masterData:any={};
  clients:any=[];
  isView:boolean=false;
  feedbackId:string=""
  clientId:string=""
  isSmithAdmin:boolean=false;
  constructor(private formBuilder:FormBuilder,
    private commonService:CommonService,
    private toastr: ToastrService,
    private route:ActivatedRoute,
    private globalService:GlobalService ) { 
      this.isView= route.snapshot.data[0]['view'];
      this.feedbackId= route.snapshot.params['feedbackId'];
    }
    async getIsAdmin(){
      let response = await  this.globalService.callfucntion();
      this.isSmithAdmin=response[0].admin;
      if(!this.isSmithAdmin)
      {
        this.clientId=response[0].id;
        this.feedbackForm.patchValue({'clientId':response[0].id});
      }
    }
    async ngOnInit(){
      this.getIsAdmin();
    this.feedbackForm = this.formBuilder.group({
      'subject': ['',Validators.required],
      'feedbackType':['',Validators.required],
      'feedback':['',Validators.required],
      'clientId':[ this.isSmithAdmin?'':this.clientId,Validators.required]
    });
    this.feedbackTypes = await  this.getMasterData("FEEDBACK_TYPE");
    this.getClients();
    if(this.isView)
{
this.get(this.feedbackId);
}
  }

  get(feedbackId:string){
    this.commonService.getData(true,this.clientCode+'/feedbackRest/'+feedbackId, {}).then(response =>{
      this.feedbackForm.patchValue({
        'subject': response.subject,
        'feedbackType': response.feedbackType,
        'feedback':response.feedback,
        'clientId':response.clientId
      });
      })};


      getClients()
      {
        this.commonService.getData(true,this.clientCode+'/client/?firstResult=-1&maxResult=0', {}).then(response =>{
          this.clients =response;
        })    
      }

  saveFeedback(feedbackForm:any)
  {
    this.submitted = true;
    // stop here if form is invalid
    if (this.feedbackForm.invalid) {
      this.toastr.error('Invalid form entries','Error');
      return;
     }
     this.commonService.postData(true,  this.clientCode+'/feedbackRest/',feedbackForm)
     .then((response:any)=>{
       if(response.success)
       {
        this.toastr.success("Thanks, your valuable feedback has been saved successfully.", 'Success');
        this.submitted = false;
        this.feedbackForm.reset();
       }
       else
       {
        this.toastr.error("Some thing went wrong", 'Error');
       }
     
   
  });


}
async getMasterData(key):Promise<any> 
{
  this.masterData.key=key;
  return  await this.commonService.getData(true,this.clientCode+'/applicationSettingsRest/?firstResult=-1&maxResult=0', this.masterData);
}
}
