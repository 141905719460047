import { Component, OnInit } from '@angular/core';
import { KeycloakProfile } from 'keycloak-js';
import { KeycloakService } from 'keycloak-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CommonService } from './service/common.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})  
export class AppComponent implements OnInit {
  
  keyCloakuserDetails: KeycloakProfile={};
  userDetails:any={};
  title = 'projectsmith';
  status: boolean = false;
  contextRoot: string ="";
  clientCode: string ="";
  noOfNotification:number=0;
  notifications:any=[];
  constructor(private keycloakService: KeycloakService,private commonService: CommonService,private router: Router) {}
  
  

  async ngOnInit() {
    if (await this.keycloakService.isLoggedIn()) {
      this.clientCode= environment.clientCode;
      this.contextRoot = environment.contextRoot;
      this.keyCloakuserDetails = await this.keycloakService.loadUserProfile();
      let object:any ={}
      object.userName = this.keyCloakuserDetails.username;
      this.commonService.postData(true,  this.clientCode+'/users/getUserByUsername',object)
      .then((response:any)=>{
      this.userDetails= response
     this.getNotification();
      this.commonService.getData(true,  this.clientCode+'/client/getClientSubscription',{})
      .then((response:any)=>{
        if(response.success)
        {
          if(response.object != null)
          {
            this.userDetails.availableHours = response.object
            this.userDetails.availableHours= this.userDetails.availableHours+" Available Hours "  
          }
          }
     
  });
  });

  
  let timerId = setInterval(() => this.getNotification() , 300000);


      
    }
  }

  async doLogout() {
    await this.keycloakService.logout();
  }

  
  updateNotification()
  {
    this.commonService.updateData(true,this.clientCode+'/notification/updateNotification/'+this.userDetails.id,{}).then((response:any)=>{
      if (response.success) {
      this.noOfNotification=0;
      }
  });
  }

  notificationNotifyToggle(referenceId:any,notificationType:any,id:any)
  {
    this.commonService.updateData(true,this.clientCode+'/notification/readNotification/'+ id, {}).then((response:any)=> {
    if (response) {
  this.getNotification(); 
if (notificationType == "QUERY") {
  this.router.navigate(['/'+environment.contextRoot+'/query/' + referenceId + '/edit']);
}
if (notificationType == "DOCUMENT") {
  this.router.navigate(['/'+environment.contextRoot+'/documentupdate/' + referenceId + '/edit']);
}
if (notificationType == "FEEDBACK") {
  this.router.navigate(['/'+environment.contextRoot+'/feedback/' + referenceId + '/view']);
} 


}

});
  }



 getNotification()
 {
  this.commonService.getData(true,  this.clientCode+'/notification/?firstResult=0&maxResult=5',{notifyTo:this.userDetails.id})
  .then((response:any)=>{
    this.notifications=response;
        console.log("this is timeout log")
        this.commonService.getData(true,  this.clientCode+'/notification/getNotificationCount/'+this.userDetails.id,{}).then((countResponse:any)=>{
          if(countResponse)
          {
          this.noOfNotification=countResponse;
           console.log("this is timeout log2") 
          }
          else
          {
            this.noOfNotification=0;
          }
       
      })
})
 }
 


}