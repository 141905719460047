<div class="page-content">
    <div class="page-title">
      <div class="title_left">
        <h3>Add Timesheet</h3>
      </div>
    </div>
    <div class="clearfix"></div>
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="x_panel">
          <div class="x_content">
              <form [formGroup]="timesheetForm" (ngSubmit)="saveTimesheet(timesheetForm.value)"class="form-horizontal form-label-left">
                <div *ngIf="isSmithAdmin" class="form-group col-md-6 col-xs-12">
                    <label class="control-label col-md-3 col-sm-3 col-xs-12">Organization*</label>
                    <div class="col-md-9 col-sm-9 col-xs-12">
                        <select class="form-control" formControlName="clientId" name="clientId" [ngClass]="{ 'is-invalid': submitted &&  timesheetForm.controls.clientId.errors }" [attr.disabled]="isEdit ?'':null">
                            <option [value]="''">Choose option</option>
                            <option *ngFor="let client of clients"  [value]="client.id">{{client.name}}</option>
                        </select>
                        <div *ngIf="(timesheetForm.controls.clientId.touched && timesheetForm.controls.clientId.invalid) || (submitted && timesheetForm.controls.clientId.errors)" class="red">
                            Organization is required.
                          </div>
                    </div>
                </div> 

                <div  class="form-group col-md-6 col-xs-12">
                    <label class="control-label col-md-3 col-sm-3 col-xs-12">Date</label>
                    <div class="col-md-9 col-sm-9 col-xs-12">
                        <input type="text"  [readonly]="disableField" class="form-control" id="date" formControlName="date" placeholder="Date" [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" name="date" [ngClass]="{ 'is-invalid': submitted &&  timesheetForm.controls.date.errors }" required="required">
                        <owl-date-time pickerType="calendar"  [disabled]="isEdit ?'':null" #dt1></owl-date-time>
                        <div *ngIf="(timesheetForm.controls.date.touched && timesheetForm.controls.date.invalid) || (submitted && timesheetForm.controls.date.errors)" class="red">
                          Date is required.
                          </div>
                    </div>
                </div> 
                <div class="form-group col-md-6 col-xs-12">
                    <label class="control-label col-md-3 col-sm-3 col-xs-12">Activity</label>
                    <div class="col-md-9 col-sm-9 col-xs-12">
                        <input type="text" class="form-control" placeholder="Activity"  formControlName="activity"  [ngClass]="{ 'is-invalid': submitted &&  timesheetForm.controls.query.errors }" [attr.disabled]="isEdit ?'':null">
                        <div *ngIf="(timesheetForm.controls.activity.touched && timesheetForm.controls.activity.invalid) || (submitted && timesheetForm.controls.activity.errors)" class="red">
                          Activity is required.
                          </div>  
                      </div>
                </div>

                  <div class="form-group col-md-6 col-xs-12">
                      <label class="control-label col-md-3 col-sm-3 col-xs-12">Query</label>
                      <div class="col-md-9 col-sm-9 col-xs-12">
                          <input type="text" class="form-control" placeholder="Query"  formControlName="query" [value]="search2" ngxTypeahead [taList]="queries" [taItemTpl]="myTemp" (taSelected)="selectedTempStatic($event)" [ngClass]="{ 'is-invalid': submitted &&  timesheetForm.controls.query.errors }" [attr.disabled]="isEdit ?'':null">
                          <ng-template #myTemp let-item>
                            <div class="item-name">
                              {{item.result.subject}}
                            </div>
                          </ng-template>
                          <div *ngIf="(timesheetForm.controls.query.touched && timesheetForm.controls.query.invalid) || (submitted && timesheetForm.controls.query.errors)" class="red">
                            Query is required.
                            </div>  
                        </div>
                  </div>
                  <div class="form-group col-md-6 col-xs-12">
                    <label class="control-label col-md-3 col-sm-3 col-xs-12">Start time</label>
                    <div class="col-md-9 col-sm-9 col-xs-12">
                        <input type="text"  [readonly]="disableField" class="form-control" id="startTime" formControlName="startTime" placeholder="Start Time" [owlDateTime]="dt2" [owlDateTimeTrigger]="dt2" name="startTime" [ngClass]="{ 'is-invalid': submitted &&  timesheetForm.controls.startTime.errors }" required="required"  >
                        <owl-date-time pickerType="timer" [disabled]="isEdit ?'':null" #dt2></owl-date-time>
                        <div *ngIf="(timesheetForm.controls.startTime.touched && timesheetForm.controls.startTime.invalid) || (submitted && timesheetForm.controls.startTime.errors)" class="red">
                            Start Time is required.
                          </div> 
                    </div>
                </div>
                
                
                  <div class="form-group col-md-6 col-xs-12">
                      <label class="control-label col-md-3 col-sm-3 col-xs-12">End Time</label>
                      <div class="col-md-9 col-sm-9 col-xs-12">
                        <input type="text"  [readonly]="disableField" class="form-control" id="endTime" formControlName="endTime" placeholder="End Time" [owlDateTime]="dt3" [owlDateTimeTrigger]="dt3" name="endTime" [ngClass]="{ 'is-invalid': submitted &&  timesheetForm.controls.endTime.errors }" required="required" >
                        <owl-date-time pickerType="timer" [disabled]="isEdit ?'':null" #dt3></owl-date-time>
                        <div *ngIf="(timesheetForm.controls.endTime.touched && timesheetForm.controls.endTime.invalid) || (submitted && timesheetForm.controls.endTime.errors)" class="red">
                            End Time is required.
                          </div> 
                      </div>
                  </div>

                  <div class="form-group col-md-6 col-xs-12">
                    <label class="control-label col-md-3 col-sm-3 col-xs-12">Hours(HH:MM)</label>
                    <div class="col-md-9 col-sm-9 col-xs-12">
                        <input type="text" class="form-control" placeholder="Hours" id="hours" appDurationPicker formControlName="hours"  [ngClass]="{ 'is-invalid': submitted &&  timesheetForm.controls.hours.errors }" [attr.disabled]="isEdit ?'':null">
                        <div *ngIf="(timesheetForm.controls.hours.touched && timesheetForm.controls.hours.invalid) || (submitted && timesheetForm.controls.hours.errors)" class="red">
                          Hours is required.
                          </div>  
                      </div>
                </div>
                
                  <div class="row">
                    <div class="form-group col-md-12 col-xs-12 text-right">
                        <div class="col-md-9 col-sm-9 col-xs-12 col-md-offset-3">
                            <button type="button" routerLink="/{{contextRoot}}/timesheetSearch" routerLinkActive="active"  class="btn btn-primary">Cancel</button>
                            <button type="Submit" class="btn btn-default submit-btn">Submit</button>
                        </div>
                    </div>
                    </div>
                  </form>
          </div>
        </div>
      </div>
    </div>
</div>
