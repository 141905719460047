import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../service/common.service';

export interface DialogData {
  name: string;
}

@Component({
    selector: 'app-km',
    templateUrl: './km.component.html',
    styleUrls: ['./km.component.css']
  })

  export class KMComponent implements OnInit {

    name: string;
    constructor( private router: Router,
    public dialog: MatDialog,
    private route:ActivatedRoute){ 

    }

    contextRoot:string=environment.contextRoot;
    ngOnInit(): void {
        
    
    }
    
    openDialog(): void {
      const dialogRef = this.dialog.open(DialogOverviewExampleDialog ,
        {
          height: '400px',
          width: '600px',
        });
  
    }
    playvideo(queryId){
      this.router.navigate(['/'+environment.contextRoot+'/videoplayer/' + queryId + '/view']);
   }
  }

 
  @Component({
    selector: 'dialog-overview-example-dialog',
    templateUrl: 'dialog-overview-example-dialog.html',
  })
  export class DialogOverviewExampleDialog  {
    constructor(public dialogRef: MatDialogRef<DialogOverviewExampleDialog>) { }
    
    }
  
  