import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../service/common.service';
import { UploadfileService } from '../service/uploadfile.service';
import { GlobalService } from '../service/global.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-document-update',
  templateUrl: './document-update.component.html',
  styleUrls: ['./document-update.component.css']
})
export class DocumentUpdateComponent implements OnInit {
  contextRoot:string=environment.contextRoot;
  clientCode:string="";
  documentForm:any;
  masterData:any={};
  documentCategoryList:any=[];
  documentFiles:any=[];
  clients:any=[];
  assignees:any=[];
  documentTypes:any=[];
  submitted :boolean= false;
  isEdit:boolean=false;
  isSmithAdmin:boolean=null;
  isCreate : boolean=false;
  documentId:string="";
  fileToUpload: File = null;
  filename:string="";
  progress = 0;
  message = '';
  clientId:any="";
  editable:boolean=false;
  
  constructor(private formBuilder: FormBuilder, 
    private toastr: ToastrService,
    private commonService:CommonService,
    private route:ActivatedRoute, 
    private router: Router,
    private uploadService: UploadfileService,
    private globalService: GlobalService) { 
      this.isEdit= route.snapshot.data[0]['edit'];
      this.isCreate= route.snapshot.data[0]['create'];
      this.documentId= route.snapshot.params['documentId'];
      console.info(this.documentId);
      this.clientCode= environment.clientCode;
      this.getIsAdmin();
      
    }
    async getIsAdmin(){
      let response = await  this.globalService.callfucntion();
      this.isSmithAdmin=response[0].admin;
      if(!this.isSmithAdmin)
      {
        this.clientId=response[0].id;
        this.documentForm.patchValue({'clientId':response[0].id});
      }
      }
 async ngOnInit() {
   
  this.documentForm = this.formBuilder.group({
    'documentName': ['',Validators.required],
    'documentCategory':[''],
    'documentType':[''],
    // 'uploadedBy':[''],
    'clientId': [ this.isSmithAdmin?'':this.clientId,Validators.required],
    'fileName':[''],
    'fileId':[''],
    'documentNumber':['']
  });
 this.documentCategoryList = await  this.getMasterData("DOCUMENT_CATEGORY");
 this.documentTypes = await  this.getMasterData("DOCUMENT_TYPE");
 this.getClients();
 this.getAllAssignees();
if(this.documentId)
{
this.get(this.documentId);
this.getfiles();
}
  }


  getClients()
  {
    this.commonService.getData(true,this.clientCode+'/client/?firstResult=-1&maxResult=0', {}).then(response =>{
      this.clients =response;
    })    
  }
  
  get(documentId:string){
    this.commonService.getData(true,this.clientCode+'/documentRest/'+documentId, {}).then(response =>{
      this.documentForm.patchValue({
        'documentName': response.documentName,
        'documentCategory': response.documentCategory,
        'documentType':response.documentType,
        // 'uploadedBy':response.uploadedBy,
        'clientId':response.clientId,
        'fileName':response.fileName,
        'fileId':response.fileId,
        'documentNumber':response.documentNumber
      });
  })};


  getfiles(){
    let object:any={}
    object.documentId=this.documentId;
    this.commonService.getData(true,this.clientCode+'/documentFileJoinRest/?firstResult=-1&maxResult=0', object).then(response =>{
      this.documentFiles=response;
  })};

  getAllAssignees(){
    let object:any={}
    this.commonService.getData(true,this.clientCode+'/assigneeRest/?firstResult=-1&maxResult=0', object).then(response =>{
      this.assignees=response;
  })};

  saveDocument(documentData:any)
  {
    this.submitted = true;
    // stop here if form is invalid
    if (this.documentForm.invalid) {
      this.toastr.error('Invalid form entries','Error');
      return;
          }
if(this.isEdit)
{
  documentData.id =  this.documentId;
this.commonService.updateData(true,this.clientCode+'/documentRest/', documentData).then( response=> {
if (response.success) {
this.get(response.object.id);
this.toastr.success("Document Updated Successfully", 'Success');
this.router.navigate(['/'+environment.contextRoot+'/documentupdate/' + response.object.id + '/edit']);
}
else {
this.toastr.error(response.message, 'Error');
}
});
}
else
{
  
  this.commonService.postData(true,this.clientCode+'/documentRest/', documentData).then( response => {
    if (response.success){
    this.toastr.success("Document Saved Successfully", 'Success');
    this.submitted = false;
    this.documentForm.reset();
    this.router.navigate(['/'+environment.contextRoot+'/documents/']);
  }
    else {
    this.toastr.error(response.messages[0].message, 'Error');
    }
    });
    
  }

}
handleFileInput(files: FileList) {
  this.fileToUpload = files.item(0);
  this.filename = this.fileToUpload.name;
}

upload() {
  this.progress = 0;
  this.uploadService.upload(this.clientCode+'/fileRest/upload/false/'+this.documentForm.value.clientId,this.fileToUpload,this.documentId+"_"+this.fileToUpload.name).subscribe(
    event => {
      if (event.type === HttpEventType.UploadProgress) {
        this.progress = Math.round(100 * event.loaded / event.total);
        
      } else if (event instanceof HttpResponse) {
        this.message = event.body.message;
       let object:any={};
       object.documentId=this.documentId;
       object.clientId=this.documentForm.value.clientId;
       object.filename=this.documentId+"_"+this.fileToUpload.name;
        this.commonService.postData(true,this.clientCode+'/documentFileJoinRest/', object).then( response => {
          if (response.success){
            this.fileToUpload = null;
             this.filename = "";
            this.toastr.success("File Uploaded Successfully", 'Success');
           window.location.reload();
        }
          else {
          this.toastr.error(response.messages[0].message, 'Error');
          }
          });
      }
      
    },
    err => {
      if(err.status = 417)
      this.toastr.error("file already exists", 'Error');
      else{
        this.progress = 0;
        this.message = 'Could not upload the file!';
        this.toastr.error(this.message, 'Error');
      }
     
    });

}


download(filename:string) {
  this.uploadService.getFiles(this.clientCode+'/fileRest/files/'+filename+'/false').subscribe(response => {
    let blob:any = new Blob([response], { type: 'text/json; charset=utf-8' });
    const url= window.URL.createObjectURL(blob);
  //  window.open(url);
 // window.location.href = "/"+response.url;  
    FileSaver.saveAs(blob, filename);
  }), error => console.log('Error downloading the file'),
               () => console.info('File downloaded successfully');
}


async getMasterData(key):Promise<any> 
{
  this.masterData.key=key;
  return  await this.commonService.getData(true,this.clientCode+'/applicationSettingsRest/?firstResult=-1&maxResult=0', this.masterData);
}


}
