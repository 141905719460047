<div class="page-content">
    <div class="page-title">
      <div class="title_left">
        <h3>Create New Contract Management</h3>
      </div>
    </div>
    <div class="clearfix"></div>
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="x_panel">
          <div class="x_content">
              <form [formGroup]="documentForm" (ngSubmit)="saveDocument(documentForm.value)"class="form-horizontal form-label-left">
                <div *ngIf="isSmithAdmin" class="form-group col-md-6 col-xs-12">
                    <label class="control-label col-md-3 col-sm-3 col-xs-12">Organization*</label>
                    <div class="col-md-9 col-sm-9 col-xs-12">
                        <select class="form-control" formControlName="clientId" name="clientId" [ngClass]="{ 'is-invalid': submitted &&  documentForm.controls.clientId.errors }" [attr.disabled]="isEdit ?'':null">
                            <option [value]="''">Choose option</option>
                            <option *ngFor="let client of clients"  [value]="client.id">{{client.name}}</option>
                        </select>
                        <!-- <input type="text" class="form-control" id="clientId" formControlName="clientId" placeholder="Organization" > -->
                        <div *ngIf="(documentForm.controls.clientId.touched && documentForm.controls.clientId.invalid) || (submitted && documentForm.controls.clientId.errors)" class="red">
                            Organization is required.
                          </div>  
                    </div>
                </div> 

                <div *ngIf="documentForm.controls.documentName != null" class="form-group col-md-6 col-xs-12">
                    <label class="control-label col-md-3 col-sm-3 col-xs-12">Document Number</label>
                    <div class="col-md-9 col-sm-9 col-xs-12">
                        <input type="text" class="form-control" id="documentNumber" formControlName="documentNumber" placeholder="Document Number" [attr.disabled]="''">
                    </div>
                </div> 

                  <div class="form-group col-md-6 col-xs-12">
                      <label class="control-label col-md-3 col-sm-3 col-xs-12">Document Name*</label>
                      <div class="col-md-9 col-sm-9 col-xs-12">
                          <input type="text" class="form-control" placeholder="Document Name"  formControlName="documentName"  [ngClass]="{ 'is-invalid': submitted &&  documentForm.controls.documentName.errors }" [attr.disabled]="isEdit ?'':null">
                          <div *ngIf="(documentForm.controls.documentName.touched && documentForm.controls.documentName.invalid) || (submitted && documentForm.controls.documentName.errors)" class="red">
                            Document Name is required.
                            </div>  
                        </div>
                  </div>
                  <div class="form-group col-md-6 col-xs-12">
                    <label class="control-label col-md-3 col-sm-3 col-xs-12">Document Category</label>
                    <div class="col-md-9 col-sm-9 col-xs-12">
                        <select class="form-control" formControlName="documentCategory" name="documentCategory" [attr.disabled]="isEdit ?'':null">
                            <option>Choose option</option>
                            <option *ngFor="let documentCategory of documentCategoryList">{{documentCategory.value}}</option>
                        </select>
                    </div>
                </div>
                
                
                  <div class="form-group col-md-6 col-xs-12">
                      <label class="control-label col-md-3 col-sm-3 col-xs-12">Document Type</label>
                      <div class="col-md-9 col-sm-9 col-xs-12">
                          <select class="form-control" formControlName="documentType" name="documentType" [attr.disabled]="isEdit ?'':null">
                              <option>Choose option</option>
                              <option *ngFor="let documentType of documentTypes">{{documentType.value}}</option>
                          </select>
                      </div>
                  </div>
                  <!-- <div class="form-group col-md-6 col-xs-12">
                      <label class="control-label col-md-3 col-sm-3 col-xs-12">Uploaded By</label>
                      <div class="col-md-9 col-sm-9 col-xs-12">         
                          <select class="form-control" formControlName="uploadedBy" name="assignee" [attr.disabled]="isEdit ?'':null">
                              <option [value]="">Choose option</option>
                              <option *ngFor="let assignee of assignees" [value]="assignee.id">{{assignee.username}}</option>
                          </select>
                      </div>
                  </div> -->
                
                  <div class="row">
                    <div class="form-group col-md-12 col-xs-12 text-right">
                        <div class="col-md-9 col-sm-9 col-xs-12 col-md-offset-3">
                            <button type="button" routerLink="/{{contextRoot}}/documents" routerLinkActive="active"  class="btn btn-primary">Cancel</button>
                            <button *ngIf="(isEdit && !documentFiles.length) || isCreate" type="Submit" class="btn btn-default submit-btn">Submit</button>
                        </div>
                    </div>
                    </div>
                  </form>
                  <div class="form-horizontal form-label-left">
                    <div *ngIf="isEdit && !documentFiles.length" class="form-group col-md-6 col-xs-12">
                        <label class="control-label col-md-3 col-sm-3 col-xs-12">Upload File</label>
                        <!-- <div class="col-md-9 col-sm-9 col-xs-12">
                            <div class="form-group"> -->
                               




                                <!-- <input type="file" style="display:none" class="form-control input-file" (change)="handleFileInput($event.target.files)"> -->
                                <input style="display:none" class="inline" type="file" (change)="handleFileInput($event.target.files)" #hiddenfileinput>
                           
                                <img src="assets/chooseFile.png" class="cursor"  width="20" height="20" alt="Choose File"  (click)="hiddenfileinput.click()" [attr.disabled]="isEdit?'':null">
                                {{filename}}
                                <button type="button" class="btn btn-primary btn-sm"  *ngIf="filename"(click)="upload()" [attr.disabled]="">Upload</button>
                          
                           
                           
                                <!-- <button  type="button" (click)="upload()" class="btn btn-primary dataTables_paginate">upload file</button>
                            -->
                            <!-- </div>
                        </div> -->
                    </div>
                
                <div class="row"></div>
                    <div *ngIf="documentFiles.length"  class="form-group col-md-6 col-xs-12">
                            <label class="control-label col-md-3 col-sm-3 col-xs-12">Files</label>
                            <div class="col-md-6 col-sm-9 col-xs-12" >
                                <div *ngFor="let documentFile of documentFiles">
                               {{documentFile.filename}}
                               <i class="fa fa-download" (click)="download(documentFile.filename)" aria-hidden="true"></i>
                               <!-- <button  type="button" (click)="download(documentFile.filename)" class="" >download file</button> -->
                            </div>
                            </div>
                        
                      </div> 
                    </div>               
          </div>
        </div>
      </div>
    </div>
</div>
