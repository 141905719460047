import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { KeycloakService, KeycloakAngularModule } from 'keycloak-angular';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { initializer } from './app-init';
import { DashboardComponent } from './dashboard/dashboard.component';
import { QuerySearchComponent } from './query-search/query-search.component';
import { QueryComponent } from './query/query.component';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PermissionDirective } from './directives/permission.directive';
import { GlobalService } from './service/global.service';
import { DocumentsComponent } from './documents/documents.component';
import { DocumentUpdateComponent } from './document-update/document-update.component';
import { ProfileComponent } from './profile/profile.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule,OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { FeedbackComponent } from './feedback/feedback.component';
import { FeedbackSearchComponent } from './feedback-search/feedback-search.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { SchedulerModule } from 'angular-calendar-scheduler';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { AppointmentComponent } from './appointment/appointment.component';
import { DurationPickerDirective } from './duration-picker.directive';
import { ListAppointmentsComponent } from './list-appointments/list-appointments.component';
import { LoaderComponent } from './loader/loader.component';
import { TokenInterceptor } from './token-interceptor';
import { LoaderService } from './loader/loader.service';
import { LoaderInterceptor } from './loader-interceptor';
import { JwPaginationModule } from 'jw-angular-pagination';
import { TimesheetSearchComponent } from './timesheet-search/timesheet-search.component';
import { TimesheetComponent } from './timesheet/timesheet.component';
import { NgxTypeaheadModule } from 'ngx-typeahead';
import { BillingComponent } from './billing/billing.component';
import { KMComponent, DialogOverviewExampleDialog } from './km/km.component';
import { TutorialComponent } from './tutorial/tutorial.component';
import { MatDialogModule} from '@angular/material/dialog';

import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';

import { MatFormFieldModule } from '@angular/material/form-field';


const keycloakService = new KeycloakService();
@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    QuerySearchComponent,
    QueryComponent,
    PermissionDirective,
    DocumentsComponent,
    DocumentUpdateComponent,
    ProfileComponent,
    ChangePasswordComponent,
    FeedbackComponent,
    FeedbackSearchComponent,
    AppointmentComponent,
    DurationPickerDirective,
    ListAppointmentsComponent,
    LoaderComponent,
    TimesheetSearchComponent,
    TimesheetComponent,
    BillingComponent,
    TutorialComponent,
    KMComponent   
  ],
  imports: [
    BrowserModule,
    JwPaginationModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    KeycloakAngularModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    MatInputModule,
    MatFormFieldModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    OwlDateTimeModule, 
    OwlNativeDateTimeModule,
    NgMultiSelectDropDownModule.forRoot(),
    SchedulerModule.forRoot({ locale: 'en', headerDateFormat: 'daysRange' }),
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
    NgxTypeaheadModule
  ],
  providers: [
    GlobalService,LoaderService,
    {provide: OWL_DATE_TIME_LOCALE, useValue:{ useUtc: true }},
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      multi: true,
      deps: [KeycloakService,HttpClient]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    }
    
    
  ],
  entryComponents: [
    DialogOverviewExampleDialog, KMComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule  {}

