<!-- page content -->
    <div class="page-content">
      <div class="page-title">
          <div class="title_left">
              <h3>KNITBook</h3>
          </div>         
      </div>
      <div class="clearfix"></div>
      <div class="row">
        <div class="col-md-12">
          <div class="x_panel">
            <div class="x_title">
              <h2>KNITBook Items</h2>
              <div class="clearfix"></div>
            </div>
            <div class="x_content">
            <!-- start project list -->
            <div class="table-responsive">
              <table class="table table-striped projects table-res">
              <thead>
                <tr>
                  <th >KB #</th>
                  <th >Date of Publication|Upload</th>
                  <th class="w20">Subject</th>
                  <th  >Category</th>
                  <!-- <th mat-sort-header="ticketType">Ticket Type</th> -->
                  <th >Sub Category</th>
                  <th >Sector</th>                  
                  <th >Expert</th>
                  <th >Link</th>
                </tr>
              </thead>
              <tbody >
                <tr>
                  <td>1</td>
                  <td>Jan-08-2022</td>
                  <td>Eligibility Criteria- Specified Users-CIC</td>
                  <td>Legal</td>
                  <td>Notification & LK Note</td>
                  <td>Fintech</td>
                  <td>Arunabh Choudhary</td>
                  <td><button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal">
                    Notification
                  </button>
                  <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal1">
                    LK Note
                  </button>
</td>
                  
                </tr>
                
                </tbody>
            </table>
          </div>
          <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title text-danger" id="exampleModalLabel"></h5>
                </div>
                <div class="modal-body">
                  <div class="embed-responsive embed-responsive-16by9">
              <iframe  class="embed-responsive-item" 
              src="https://lawsmithdev.blob.core.windows.net/kmfiles/Eligibility05012022.pdf#toolbar=0&navpanes=0&scrollbar=0"
              
          ></iframe>
          </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-warning" data-dismiss="modal">Close</button>
                </div>
              </div>
            </div>
          </div>  
          <div class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
              <div class="modal-content">
                
                <div class="modal-body">
                  <div class="embed-responsive embed-responsive-16by9">
              <iframe  class="embed-responsive-item" 
              src="https://lawsmithdev.blob.core.windows.net/kmfiles/Eligibility Criteria-SU-CIC-LK Article(07-01-22).pdf#toolbar=0&navpanes=0&scrollbar=0"
              
          ></iframe>
          </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-warning" data-dismiss="modal">Close</button>
                </div>
              </div>
            </div>
          </div>  
            </div>
          </div>
        </div>


      </div>
    </div>
      
      
      
  <!-- /page content -->
 
  