  <div class="container body">
  <div class="main_container">
   
    <!-- top navigation -->
    <div class="top_nav">

      <div class="nav_menu">
        <div class="navbar nav_title">
          <a  routerLink="/{{contextRoot}}/dashboard" routerLinkActive="active" class="site_title"><img src="assets/logo.png" width="150" height="50" alt=""></a>
      </div>
        <nav class="" role="navigation">
          <div class="nav toggle">
            <a id="menu_toggle"></a>
          </div>
            <ul class="nav navbar-nav navbar-right">
              
              <li class="dropdown">
               
                <a  class="user-profile dropdown-toggle"  aria-expanded="false"  data-toggle="dropdown">
                  <img src="assets/img.jpg" alt="">{{userDetails.firstName}} {{userDetails.lastName}}
                      <span class=" fa fa-angle-down"></span>
                </a>
                <ul   class="dropdown-menu dropdown-usermenu animated fadeInDown pull-right">
                    <li><a  routerLink="/{{contextRoot}}/profile" routerLinkActive="active" >Profile</a></li>
                    <li>
                        <a href="">
                            <span class="badge bg-red pull-right">50%</span>
                            <span>Settings</span>
                        </a>
                    </li>
                    <li>
                        <a routerLink="/{{contextRoot}}/changePassword" routerLinkActive="active">Change Password</a>
                    </li>
                    <li><a (click)="doLogout()"><i class="fa fa-sign-out pull-right"></i> Log Out</a>
                    </li>
                </ul>
              </li>
      
              <li class="dropdown">

                

                <a (click)="updateNotification()" class="dropdown-toggle" data-toggle="dropdown" href="#" ><i class="fa fa-bell fa-lg"></i> 
                  <span *ngIf="noOfNotification" class="notification-label bounceIn animation-delay6">{{noOfNotification}}</span></a>
                
                <ul class="dropdown-menu dropdown-usermenu animated fadeInDown pull-right">
                  <li class="list-group-item" id="unRead" *ngFor="let notifyAll of notifications"> 
                  <div class="notify unReadNotification" *ngIf="notifyAll.read==false">
                  <div (click)="notificationNotifyToggle(notifyAll.notificationReferenceId,notifyAll.notificationType,notifyAll.id)" class="cursor">
               <div class="no-margin">
                <p style="white-space: normal !important;">
                  {{notifyAll.notificationText}}  for QueryID: {{notifyAll.notificationReferenceId}}
                  at
                    {{notifyAll.notificationTime | date:'dd MMM 
                    HH:MM'}}</p>
                  </div>
                  
                  </div>
                  </div>
                  <div class="notify" *ngIf="notifyAll.read==true">
                    <div class="cursor" (click)="notificationNotifyToggle(notifyAll.notificationReferenceId,notifyAll.notificationType,notifyAll.id)">
                    <div class="no-margin">
                      <p style="white-space: normal !important;">
                        {{notifyAll.notificationText}} for QueryID: {{notifyAll.notificationReferenceId}}
                        at
                          {{notifyAll.notificationTime | date:'dd MMM
                          HH:MM'}}</p>
                    </div>
                    
                    </div>
                    </div>
                  </li>
                  <li><a href="" >View
                  all notifications</a></li>
                </ul>
              </li>

              <li  class="dropdown">  
                <a  class="user-profile dropdown-toggle whiteColor"  aria-expanded="false"  data-toggle="dropdown">  
                  {{userDetails.availableHours}}
            </a></li>


            </ul>
            


        </nav>
      </div>
    </div>
    <!-- /top navigation -->
    <div class="right_col" role="main">
<router-outlet></router-outlet>
<!-- footer content -->
<footer class="footerColor">
  <div >
      <p class="pull-right indigoblue">@2020 All rights reserved. |
          <span class="lead"> <img src="assets/logo.png" width="150" height="50" alt=""></span>
      </p>
  </div>  
  <div class="row"></div>
</footer>
<!-- /footer content -->
 </div>
</div>
</div>
