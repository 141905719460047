import { Component, OnInit } from '@angular/core';
import { CommonService } from '../service/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { GlobalService } from '../service/global.service';
import {Sort} from '@angular/material/sort';
import { ToastrService } from 'ngx-toastr';



export interface Query {
  id: number;
  clientId: number;
  subject: string;
  documentCategory: string;
  ticketType:string;
  assignee: string;
  status: string
  createdByName: string
}

@Component({
  selector: 'app-query-search',
  templateUrl: './query-search.component.html',
  styleUrls: ['./query-search.component.css']
})
export class QuerySearchComponent implements OnInit {
  items = [];
  pageOfItems: Array<any>;
  queries:Query[];
  clients:any=[];
  clientMap:Map<string,string>=new Map();
  isSmithAdmin:boolean=null;
  clientCode:string="";
  searchCriteria:any={};
  contextRoot:string=environment.contextRoot;
  sortedData: Query[];
  assignees:any=[];
  asssigneeMap:Map<string,string>=new Map();
 
  ngOnInit(): void {
    
  }
  constructor(
    private router: Router,
    private route:ActivatedRoute,
    private toastr: ToastrService,
    private commonService: CommonService,
    private globalService: GlobalService
    ) {
    this.clientCode= environment.clientCode;
    this.getIsAdmin();
    this.commonService.getData(true,  this.clientCode+'/queryRest/?firstResult=-1&maxResult=0', {})
    .then((response:any)=>{
      this.queries= response;
    for(let i=0;i<response.length;i++){
      this.commonService.getData(true,this.clientCode+'/users/'+response[i].createdBy, {}).then(userResponse =>{
        this.queries[i].createdByName = userResponse.firstName +" "+userResponse.lastName;
      });
    }
    
    console.log(this.queries);
    this.sortedData = this.queries.slice();
    });
    this.getClientMap();
    this.getAllAssignees();
  }

  getClientMap()
  {
    this.commonService.getData(true,this.clientCode+'/client/?firstResult=-1&maxResult=0', {}).then(response =>{
      this.clients = response;
      for(let i=0; i<this.clients.length;i++ ){
        this.clientMap.set(this.clients[i].id,this.clients[i].name)

      }
      console.log(this.clientMap)
    })    
  }
  async getIsAdmin(){
    let response = await  this.globalService.callfucntion();
    this.isSmithAdmin=response[0].admin;
  }

  
  

  getAllAssignees(){
    let object:any={}
    this.commonService.postData(true,this.clientCode+'/users/getAllAssignees', object).then(response =>{
      this.assignees=response;
      for(let i=0; i<this.assignees.length;i++ ){
        this.asssigneeMap.set(this.assignees[i].id,this.assignees[i].firstName+" "+this.assignees[i].lastName)

      }
  })};
  editQuery(queryId){
     this.router.navigate(['/'+environment.contextRoot+'/query/' + queryId + '/edit']);
  }
  
  viewQuery(queryId){
    this.router.navigate(['/'+environment.contextRoot+'/query/' + queryId + '/view']);
 }
 deleteQuery(queryId:number){
  this.commonService.postData(true,this.clientCode+'/queryRest/deleteQuery/'+queryId, {}).then(response =>{
   if(response.success)
   {
    this.toastr.success("Query Deleted Successfully", 'Success');
    this.commonService.getData(true,  this.clientCode+'/queryRest/?firstResult=-1&maxResult=0', {})
    .then((response:any)=>{
      this.queries= response;
    for(let i=0;i<response.length;i++){
      this.commonService.getData(true,this.clientCode+'/users/'+response[i].createdBy, {}).then(userResponse =>{
        this.queries[i].createdByName = userResponse.firstName +" "+userResponse.lastName;
      });
    }
    
    console.log(this.queries);
    this.sortedData = this.queries.slice();
    });
   }
   else{
    this.toastr.error("Oops! there is some probelm", 'Error');
   }
})

 }
 getSearchResult()
 {
  this.commonService.getData(true,  this.clientCode+'/queryRest/?firstResult=-1&maxResult=0', this.searchCriteria)
  .then((response:any)=>{
  this.queries= response;
  for(let i=0;i<response.length;i++){
    this.commonService.getData(true,this.clientCode+'/users/'+response[i].createdBy, {}).then(userResponse =>{
      this.queries[i].createdByName = userResponse.firstName +" "+userResponse.lastName;
    });
  }
  console.log(this.queries);
  this.sortedData = this.queries.slice();
  });
 }





sortData(sort: Sort) {
  const data = this.queries.slice();
  if (!sort.active || sort.direction === '') {
    this.sortedData = data;
    return;
  }

  this.sortedData = data.sort((a, b) => {
    const isAsc = sort.direction === 'asc';
    switch (sort.active) {
      case 'id': return compare(a.id, b.id, isAsc);
      case 'clientId': return compare(a.clientId, b.clientId, isAsc);
      case 'subject': return compare(a.subject, b.subject, isAsc);
      case 'documentCategory': return compare(a.documentCategory, b.documentCategory, isAsc);
      case 'assignee': return compare(a.assignee, b.assignee, isAsc);
      case 'status': return compare(a.status, b.status, isAsc);
      case 'createdByName':return compare(a.createdByName, b.createdByName, isAsc);
      default: return 0;
    }
  });
}
 

onChangePage(pageOfItems: Array<any>) {
  // update current page of items
  this.pageOfItems = pageOfItems;
}

}
function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}