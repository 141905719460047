<div class="page-content">
    <div class="page-title">
        <div class="title_left">
            <h3>Change Password</h3>
        </div>
    </div>
    <div class="clearfix"></div>

    <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="x_panel">
                <div class="x_content">

                    <form [formGroup]="userForm" (ngSubmit)="updateUser(userForm.value)" class="form-horizontal form-label-left">
                    <div class="col-md-9 col-sm-9 col-xs-12">
                      <div class="form-group row">
                          <label class="control-label col-md-3 col-sm-3 col-xs-12" for="last-name">New Password <span class="required">*</span>
                          </label>
                          <div class="col-md-6 col-sm-6 col-xs-12">
                              <input type="password" formControlName="newPassword" name="newPassword" required="required" [ngClass]="{ 'is-invalid': submitted &&  userForm.controls.newPassword.errors }"  class="form-control col-md-7 col-xs-12">
                              <div *ngIf="(userForm.controls.newPassword.touched && userForm.controls.newPassword.invalid) || (submitted && userForm.controls.newPassword.errors)" class="red">
                                New Password  is required.
                                </div>  
                            </div>
                      </div>
                      <div class="form-group row">
                          <label class="control-label col-md-3 col-sm-3 col-xs-12">Confirm New Password<span class="required">*</span>
                          </label>
                          <div class="col-md-6 col-sm-6 col-xs-12">
                              <input  formControlName="confirmNewPassword" name="confirmNewPassword" class="date-picker form-control col-md-7 col-xs-12" [ngClass]="{ 'is-invalid': submitted &&  userForm.controls.confirmNewPassword.errors }" required="required" type="password">
                              <div *ngIf="(userForm.controls.confirmNewPassword.touched && userForm.controls.confirmNewPassword.invalid) || (submitted && userForm.controls.confirmNewPassword.errors)" class="red">
                                Confirm New Password is required.
                                </div>  
                            </div>
                      </div>
                      
                    </div>
                    <div class="form-group col-md-12 col-xs-12 text-right">
                        <div class="col-md-9 col-sm-9 col-xs-12 col-md-offset-3">
                            <button type="button" routerLink="/{{contextRoot}}/dashboard" routerLinkActive="active" class="btn btn-primary">Cancel</button>
                            <button type="Submit"  class="btn btn-default submit-btn">Update</button>
                        </div>
                    </div>
                </form>
                </div>
            </div>
        </div>
    </div>
</div>
