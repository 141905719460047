import { KeycloakConfig } from 'keycloak-angular';

// Add here your keycloak setup infos
let keycloakConfig: KeycloakConfig = {
  url: 'https://portal.lawknit.co/auth/', //Prod
  //url: 'http://localhost:8180/auth', //local
  realm: 'lawknit',
  clientId: 'angularApp',
  credentials:{
    //secret:'455b17b7-9f26-48df-a176-0d90466e22ed' //local
    secret:'b0f45d55-c043-4d51-b8ef-c8e628732aec' //Prod
  }
};


export const environment = {
  production: false,
  keycloakConfig: keycloakConfig,
//  projectUrl:"http://localhost:8080/lawknit/",
  projectUrl:"https://portal.lawknit.co/api/lawknit/",
  contextRoot:"lawknit",
  clientCode:""
  //(window.location.href.replace("http://localhost:4200/lawsmith/",'').split('/')[0]!="http:"?window.location.href.replace("http://localhost:4200/lawsmith/",'').split('/')[0]:"org1")
  
};
