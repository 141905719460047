import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms'
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../service/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { UploadfileService } from '../service/uploadfile.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import * as FileSaver from 'file-saver';
import { GlobalService } from '../service/global.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { KeycloakService } from 'keycloak-angular';
import * as moment from 'moment';
declare var $: any;

@Component({
  selector: 'app-query',
  templateUrl: './query.component.html',
  styleUrls: ['./query.component.css']
})
export class QueryComponent implements OnInit {
  queryForm:any;
  clientId:any="";
  masterData:any={};
  documentCategoryList:any=[];
  privacyQueryFileJoinList:any=[];
  subCategories:any=[];
  //ticketTypes:any=[];
  queryfiles:any=[];
  tags:any=[];
  comments:any=[];
  internalComments:any=[];
  nextStatuses:any=[];
  clients:any=[];
  assignees:any=[];
  internalAssignees:any=[]; 
  contextRoot:string=environment.contextRoot;
  submitted :boolean= false;
  isEdit:boolean=false;
  isView:boolean=false;
  isSmithAdmin:boolean=null;
  queryId:Int32Array;
  clientCode:string="";
  fileToUpload: File = null;
  filename:string="";
  privacyFlag:string="";
  progress = 0;
  message = '';
  comment :string="";
  privacyUsers:any=[];
  selectedPrivacyUsers:any=[];
  appointments:any=[];
  dropdownSettings:IDropdownSettings = {};
  keyCloakuserDetails:any={};
  userDetails:any={};
  queryCreatedBy:string="";
  queryCreationDate:string="";
  etdDisable: Boolean= true; 
  queryStatus: string="";
  currentStatus: string="";
  privacyUserMap:Map<string,string>=new Map();
   constructor(
    private formBuilder: FormBuilder, 
    private toastr: ToastrService,
    private commonService:CommonService,
    private route:ActivatedRoute, 
    private router: Router,
    private uploadService: UploadfileService,
    private globalService: GlobalService,
    private keycloakService: KeycloakService) {
    this.isEdit= route.snapshot.data[0]['edit'];
    this.isView= route.snapshot.data[0]['view'];
    this.queryId= route.snapshot.params['queryId'];
    this.clientCode= environment.clientCode;

        
    }
    async getIsAdmin(){
      let response = await  this.globalService.callfucntion();
      this.isSmithAdmin=response[0].admin;
      if(!this.isSmithAdmin)
      {
        this.clientId=response[0].id;
        this.queryForm.patchValue({'clientId':response[0].id});
      }
    }
    async getuserDetails(){
      this.keyCloakuserDetails = await this.keycloakService.loadUserProfile();
      let object:any ={}
      object.userName = this.keyCloakuserDetails.username;
      this.commonService.postData(true,  this.clientCode+'/users/getUserByUsername',object)
      .then((response:any)=>{

        this.userDetails= response;
      });
    }

    async ngOnInit() {
      this.getIsAdmin();
      this.getuserDetails();
    this.queryForm = this.formBuilder.group({
      'subject': ['',Validators.required],
      'description': ['',Validators.required],
      'documentCategory':[''],
      'estimatedEffort':[''],
      'subCategory':[''],
      'priority':[''],
      // 'ticketType':[''],
      'assignee':[''],
      'internalAssignee':[''],
      'actualEffort':[''],
      'billedEffort':[''],
      'billedDate':[''],
      'etd':[''],
      'privacySettings':[''],
      'tags':[''],
      'clientId': [ this.isSmithAdmin?'':this.clientId,Validators.required],
      'fileName':[''],
      'fileId':[''],
      'status':[''],
      'selectedPrivacyUsers':null,
      'createdByName':[]
      });
   this.documentCategoryList = await  this.getMasterData("DOCUMENT_CATEGORY");
   this.privacyQueryFileJoinList = await  this.getMasterData("QUERY_FILE_JOIN_PRIVACY_FLAG");
  
  // this.ticketTypes = await  this.getMasterData("TICKET_TYPE");
   this.getClients();
   this.getAllAssignees();
   this.getAllInternalAssignees()
   this.getAllPrivacyUser();
   this.etdDisable = ((this.queryForm.value.internalAssignee == this.userDetails.id || this.isSmithAdmin) && !this.isView ) ?false:true;
   this.dropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'userName',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
  };
  this.queryStatus = "";
if(this.isEdit)
{
this.getAppointments();  
await this.get(this.queryId);
this.getfiles();
this.getcomments();
this.getInternalComments();


}
  }

  

  getClients()
  {
    this.commonService.getData(true,this.clientCode+'/client/?firstResult=-1&maxResult=0', {}).then(response =>{
      this.clients =response;
    })    
  }
  async get(queryId){
     this.commonService.getData(true,this.clientCode+'/queryRest/'+queryId, {}).then(response =>{
      if (response.etd != null){
        response.etd = new Date(Number(moment(response.etd).format('YYYY')),
        Number(moment(response.etd).format('MM'))-1,
        Number(moment(response.etd).format('DD')),
        Number(moment(response.etd).format('HH')),
        Number(moment(response.etd).format('mm')),);
      }
      if (response.billedDate != null){
        response.billedDate = new Date(Number(moment(response.billedDate).format('YYYY')),
        Number(moment(response.billedDate).format('MM'))-1,
        Number(moment(response.billedDate).format('DD')),
        Number(moment(response.billedDate).format('HH')),
        Number(moment(response.billedDate).format('mm')),);
      }
      this.commonService.getData(true,this.clientCode+'/users/'+response.createdBy, {}).then(userResponse =>{
          this.queryForm.patchValue({
        'subject': response.subject,
        'description': response.description,
        'documentCategory':response.documentCategory,
        'estimatedEffort':response.estimatedEffort,
        'subCategory':response.subCategory,
        'priority':response.priority,
        // 'ticketType':response.ticketType,
        'assignee':response.assignee,
        'actualEffort':response.actualEffort,
        'billedEffort':response.billedEffort,
        'billedDate':response.billedDate,
        'etd':response.etd,
        'privacySettings':response.privacySettings,
        'tags':response.tags,
        'fileName':response.fileName,
        'fileId':response.fileId,
        'status':response.status,
        'clientId':response.clientId,
        'createdBy':response.createdBy,
        'createdByName': userResponse.firstName +" "+userResponse.lastName,
        'internalAssignee':response.internalAssignee,
      });

      this.getnextStatus();
      this.etdDisable = ((this.queryForm.value.internalAssignee == this.userDetails.id || this.isSmithAdmin) && !this.isView ) ?false:true;
      });
      




      
      this.tags=response.tags.split(",")
      this.queryStatus=response.status;
      this.subCategories=  this.getSubCategories();
      this.queryCreatedBy = response.createdBy;
      if(response.privacySettings!="")
      {
        let object:any={}
    object.clientId=this.clientId;
    this.commonService.getData(true,this.clientCode+'/users/?firstResult=-1&maxResult=0', object).then( iresponse =>{
      this.privacyUsers=iresponse;
      for(let i=0; i<this.privacyUsers.length;i++ ){
        this.privacyUserMap.set(this.privacyUsers[i].id,this.privacyUsers[i].userName)
      }
      this.selectedPrivacyUsers=[];
      let array = response.privacySettings.split(",")
      for(let i =0; i<array.length-1; i++){
       let object:any={}
       object.id= Number(array[i]);
       object.userName= this.privacyUserMap.get(object.id);
       this.selectedPrivacyUsers.push(object);
     }
   
  })








      
      }
      
      this.queryForm.patchValue({
        'selectedPrivacyUsers':this.selectedPrivacyUsers.length>0?this.selectedPrivacyUsers:null
      })

     
  })};


  getfiles(){
    let object:any={}
    object.queryId=this.queryId;
    this.commonService.getData(true,this.clientCode+'/queryFileJoinRest/?firstResult=-1&maxResult=0', object).then(response =>{
      this.queryfiles=response;
      for(let i =0; i<this.queryfiles.length; i++)
      {
        if(this.queryfiles[i][7]==null || this.queryfiles[i][7]=="")
        this.queryfiles[i][7]="Customer"
      }
  })};

  getAllAssignees(){
    let object:any={}
    this.commonService.postData(true,this.clientCode+'/users/getAllAssignees', object).then(response =>{
      this.assignees=response;
  })};

  getAllInternalAssignees(){
    let object:any={}
    this.commonService.postData(true,this.clientCode+'/users/getAllInternalAssignees', object).then(response =>{
      this.internalAssignees=response;
      for (let i = 0; i<this.internalAssignees.length;i++){
        if(this.internalAssignees[i][5] == 0) {
          if(this.internalAssignees[i][6] != null)
            this.internalAssignees[i][6] = this.internalAssignees[i][1]+" "+this.internalAssignees[i][2]+" - " + this.internalAssignees[i][6]
          else 
            this.internalAssignees[i][6] = this.internalAssignees[i][1]+" "+this.internalAssignees[i][2]+" - CP" 
        }
        else
        this.internalAssignees[i][6] = this.internalAssignees[i][1]+" "+this.internalAssignees[i][2]+" - LK"

      }
        
   
  })};

  async  getAllPrivacyUser():Promise<any>{
    let object:any={}
    object.clientId=this.clientId;
    this.commonService.getData(true,this.clientCode+'/users/?firstResult=-1&maxResult=0', object).then(async (response) =>{
      this.privacyUsers=response;
     let privacyMap:Map<string,string>=new Map();
      for(let i=0; i<this.privacyUsers.length;i++ ){
        this.privacyUserMap.set(this.privacyUsers[i].id,this.privacyUsers[i].userName)
      }
      return this.privacyUserMap;
     
  })};
  getcomments(){
    let object:any={}
    object.queryId=this.queryId;
    object.type='PUBLIC';
    this.commonService.getData(true,this.clientCode+'/commentsRest/searchWithQuery?firstResult=-1&maxResult=0', object).then(response =>{
      this.comments=response;
  })};

  getInternalComments(){
    let object:any={}
    object.queryId=this.queryId;
    object.type='INTERNAL';
    this.commonService.getData(true,this.clientCode+'/commentsRest/searchWithQuery?firstResult=-1&maxResult=0', object).then(response =>{
      this.internalComments=response;
  })};

  getnextStatus():void{
    let object:any={}
    this.currentStatus=this.queryForm.value.status;
    object.fromState=this.queryForm.value.status;
    this.commonService.getData(true,this.clientCode+'/queryStatusChangeRest/?firstResult=-1&maxResult=0', object).then(response =>{
      this.nextStatuses=response;
  })
  }
  saveQuery(queryData)
  {
    this.submitted = true;
    // stop here if form is invalid
    if (this.queryForm.invalid) {
      this.toastr.error('Invalid form entries','Error');
      this.queryForm.value.status="Draft";
      return;
          }


queryData.tags = this.tags.toString() ;
if(this.isEdit)
{
  if (queryData.status == 'Assigned' && (queryData.assignee == '' || queryData.assignee == null) ) {
    this.toastr.error('Please select Assignee','Error');
    return;
        }
queryData.id =  this.queryId;
queryData.billedEffort=  (<HTMLInputElement>document.getElementById("billedEffort")).value;
queryData.estimatedEffort=  (<HTMLInputElement>document.getElementById("estimatedEffort")).value;
queryData.actualEffort=  (<HTMLInputElement>document.getElementById("actualEffort")).value;
queryData.createdBy = this.queryCreatedBy;
this.commonService.updateData(true,this.clientCode+'/queryRest/', queryData).then( response=> {
if (response.success) {
this.get(response.object.id);
this.toastr.success("Query Updated Successfully", 'Success');
this.router.navigate(['/'+environment.contextRoot+'/query/' + response.object.id + '/edit']);
}
else {
this.toastr.error(response.message, 'Error');
}
});
}
else
{
  queryData.status =  "Draft";
  this.commonService.postData(true,this.clientCode+'/queryRest/', queryData).then( response => {
    if (response.success){
    this.toastr.success("Query Saved Successfully", 'Success');
    this.submitted = false;
    this.queryForm.reset();
    this.router.navigate(['/'+environment.contextRoot+'/query/' + response.object.id + '/edit']);
  }
    else {
    this.toastr.error(response.messages[0].message, 'Error');
    }
    });
    
  }

}
handleFileInput(files: FileList) {
  this.fileToUpload = files.item(0);
  this.filename = this.fileToUpload.name;
}

setPrivacy(){
  if(this.isSmithAdmin || this.queryForm.value.internalAssignee == this.userDetails.id)
  $("#setPrivacyModal").modal('show');
  else
  this.upload()
}




upload() {
  this.progress = 0;
  this.uploadService.upload(this.clientCode+'/fileRest/upload/true/'+this.queryForm.value.clientId,this.fileToUpload,this.queryId+"_"+this.fileToUpload.name).subscribe(
    event => {
      if (event.type === HttpEventType.UploadProgress) {
        this.progress = Math.round(100 * event.loaded / event.total);
        
      } else if (event instanceof HttpResponse) {
        this.message = event.body.message;
       let object:any={};
       object.queryId=this.queryId;
       object.clientId=this.queryForm.value.clientId;
       object.filename=this.queryId+"_"+this.fileToUpload.name;
       if(this.privacyFlag==null || this.privacyFlag=="")
       object.privacyFlag="Customer";
       else
       object.privacyFlag=this.privacyFlag;
        this.commonService.postData(true,this.clientCode+'/queryFileJoinRest/', object).then( response => {
          if (response.success){
            this.fileToUpload = null;
             this.filename = "";
            this.toastr.success("File Uploaded Successfully", 'Success');
           window.location.reload();
        }
          else {
          this.toastr.error(response.messages[0].message, 'Error');
          }
          });
      }
      
    },
    err => {
      if(err.status = 417)
      this.toastr.error("file already exists", 'Error');
      else{
        this.progress = 0;
        this.message = 'Could not upload the file!';
        this.toastr.error(this.message, 'Error');
      }
     
    });

}


download(filename:string) {
  this.uploadService.getFiles(this.clientCode+'/fileRest/files/'+filename+'/true').subscribe(response => {
    let blob:any = new Blob([response], { type: 'text/json; charset=utf-8' });
    const url= window.URL.createObjectURL(blob);
  //  window.open(url);
 // window.location.href = "/"+response.url;  
    FileSaver.saveAs(blob, filename);
  }), error => console.log('Error downloading the file'),
               () => console.info('File downloaded successfully');
}

saveComment(comment:string, type:string) {
  let object:any={};
  object.queryId=this.queryId;
  object.comment=comment;
  object.type=type;
  object.clientId=this.queryForm.value.clientId;
  this.commonService.postData(true,this.clientCode+'/commentsRest/', object).then( response => {
  if (response.success){
  this.toastr.success("Comment Saved Successfully", 'Success');
  this.comment="";
  window.location.reload();
}
  else {
  this.toastr.error(response.messages[0].message, 'Error');
  }
  });
  }

  saveTag(event:any)
  {
    this.tags.push(event.target.value)
    event.target.value ="";
  }
  removeTag(value:any)
  {
    const index = this.tags.indexOf(value);
    if (index > -1) {
      this.tags.splice(index, 1);
    }
  }
  onItemSelect(item: any) {
    console.log(item);
    let values= this.queryForm.value.privacySettings
    if(values== null || values== undefined || values=="")
    values = item.id+",";
    else
    values = values+item.id+",";
    this.queryForm.patchValue({
      'privacySettings':values,
    });
   
  }
  onSelectAll(items: any) {
    console.log(items);
    let values:string = "";
    for(let i =0; i<items.length;i++)
    {
      values=values+items[i].id+",";
    }
     this.queryForm.patchValue({
      'privacySettings':values,
    });
  }
  public onDeSelect(item: any) {
    console.log(item);
    let values= this.queryForm.value.privacySettings
    values= values.replace(item.id+",","");
    this.queryForm.patchValue({
      'privacySettings':values,
    });
  }
  public onDeSelectAll(items: any) {
    console.log(items);
    this.queryForm.patchValue({
      'privacySettings':'',
    });
  } 

async getMasterData(key):Promise<any> 
{
  this.masterData.key=key;
  return  await this.commonService.getData(true,this.clientCode+'/applicationSettingsRest/?firstResult=-1&maxResult=0', this.masterData);
}

async getSubCategories() 
  {
    this.masterData.key=this.queryForm.value.documentCategory+"_SUB_CATEGORY";
    this.commonService.getData(true,this.clientCode+'/applicationSettingsRest/?firstResult=-1&maxResult=0', this.masterData).then(response =>{
      return  this.subCategories=response;
  });
  }
  OnSubmit(){
    this.queryForm.value.status = "New";
    this.saveQuery(this.queryForm.value);
  }
  OnNoUrgentClick(){
    this.queryForm.value.priority = false;
  }
  OnYesUrgentClick(){
    this.queryForm.value.priority = true;
  }
 
  deletefile(filename:string, queryfileJoinId:number){
    this.commonService.postData(true,  this.clientCode+'/queryFileJoinRest/deleteFile/'+queryfileJoinId,{})
    .then((response:any)=>{
      if (response.success){
        this.toastr.success("File Deleted Successfully", 'Success');
        window.location.reload();
      }
        else {
        this.toastr.error(response.messages[0].message, 'Error');
        }
    });
  }

  getAppointments(){
    let object:any={};
    object.queryId= this.queryId;
    this.commonService.getData(true,this.clientCode+'/appointmentRest?firstResult=-1&maxResult=0', object).then(response =>{
      this.appointments=response;
  });

  }

}
