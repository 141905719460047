import { Directive, Input, ElementRef, Renderer2, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { CommonService } from '../service/common.service';
import { environment } from 'src/environments/environment';

@Directive({
  selector: '[appPermission]'
})
export class PermissionDirective implements OnInit {
  @Input("appPermission") xyz : string;
  roles : any=[];
  clientCode:string= environment.clientCode
  constructor(private el: ElementRef,
  private renderer:Renderer2,
  private keycloakService:KeycloakService,
  private commonService:CommonService) {
  }
  
  ngOnInit() {
  
  let permissionPages = this.xyz.split(" || ");
  this.commonService.postData(true,this.clientCode+'/users/getPermissions', {}).then(response =>{
    this.roles = response;
    this.renderer.setStyle(this.el.nativeElement, 'display', "none")
  if (this.roles.length != 0) {
  for (var i = 0; i < this.roles.length; i++) {
  if (permissionPages.indexOf(this.roles[i].permissionCode) != -1) {
  this.renderer.setStyle(this.el.nativeElement, 'display', "block");
  }
  }
  }
});
 
  
  
  }}