<div class="page-content">
    <div class="page-title">
        <div class="title_left">
            <h3>Feedbacks</h3>
        </div>
        <div class="title_right">
            <div class="col-md-5 col-sm-5 col-xs-12 form-group pull-right top_search">
              <form  (ngSubmit)="getSearchResult()">  
              <div class="input-group">
                    <input type="text" class="form-control" [(ngModel)]="searchCriteria.subject" name="subject" placeholder="Feedback Search...">
                    <span class="input-group-btn">
            <button  class="btn btn-default submit-btn" type="Submit">Go!</button>
          </span>
                </div>
              </form>
            </div>
        </div>
    </div>
    <div class="clearfix"></div>
    <div class="row">
      <div class="col-md-12">
        <div class="x_panel">
          <div class="x_title">
            <h2>Feedbacks</h2>
            <a routerLink="/{{contextRoot}}/feedback" routerLinkActive="active" class="btn btn-default submit-btn pull-right"> <i class="fa fa-plus"></i> Add Feedback</a>
            <div class="clearfix"></div>
          </div>
          <div class="x_content">
          <!-- start project list -->
          <div class="table-responsive">
          <table class="table table-striped projects table-res"  matSort (matSortChange)="sortData($event)">
            <thead>
              <tr>
                <th mat-sort-header="id">Document ID</th>
                <th mat-sort-header="clientId" *ngIf="isSmithAdmin">Organization</th>
                <th mat-sort-header="subject">Subject</th>
                <th mat-sort-header="feedbackType">Feedback Type</th>
                <th class="w20">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr  *ngFor="let feedback of sortedData">
                <td>{{feedback.id}}</td>
                <td *ngIf="isSmithAdmin">{{clientMap.get(feedback.clientId)}}</td>
                <td>{{feedback.subject}}</td>
                <td>{{feedback.feedbackType}}</td>
                <td>
                    <button  (click)="viewFeedback(feedback.id)" class="btn btn-primary btn-xs"><i class="fa fa-folder"></i> View </button>
                  </td>
                
               </tr>
            </tbody>
          </table>
        </div>
          <!-- end project list -->
          </div>
        </div>
      </div>
    </div>
  </div>
