import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  public clientCode:string="client";
  constructor(private http:HttpClient,
  private toastr: ToastrService,
  private router:Router,
  private keycloakService: KeycloakService) {
  }
  postData(isSecured,route, data) {
  if(isSecured)
  {
    return this.http.post(environment.projectUrl+ route, data).toPromise().then(
  (data:any) =>{
  return data;
  },
  (error:any) =>{
  console.log("ERROR", error);
  if(error.status==401)
  {
  if(error.error.reason=="NOT_AUTHORIZED")
  {
  this.toastr.error('You are not authorized','Error');
  this.logout() ;
  }
  else
  {
  this.toastr.error('Invalid access token','Error');
  
  }
  }
  else if(error.status==403)
  {
  this.toastr.error('You should be logged in','Error');
  //
  }
  else if(error.status==500)
  {
  this.toastr.error('Some Error Occured. Please try after sometime','Error');
  //
  }
  else if(error.status==404)
  {
  this.toastr.error('Some Error Occured. Please contact administrator','Error');
  //
  }
  });
  }
  else
  {this.http.post(environment.projectUrl+ route, data).subscribe(
  (data:any) =>{
  return data;
  },
  (error:any) =>{
  console.log("ERROR", error);
  if(error.status==401)
  {
  if(error.error.reason=="NOT_AUTHORIZED")
  {
  this.toastr.error('You are not authorized','Error');
  this.logout() ;
  }
  else
  {
  this.toastr.error('Invalid access token','Error');
  
  }
  }
  else if(error.status==403)
  {
  this.toastr.error('You should be logged in','Error');
  //
  }
  else if(error.status==500)
  {
  this.toastr.error('Some Error Occured. Please try after sometime','Error');
  //
  }
  else if(error.status==404)
  {
  this.toastr.error('Some Error Occured. Please contact administrator','Error');
  //
  }
  });}
  }
  
  updateData(isSecured,route, data) {
  if(isSecured)
  {return this.http.put(environment.projectUrl+ route, data).toPromise().then(
  (data:any) =>{
  return data;
  },
  (error:any) =>{
  console.log("ERROR", error);
  if(error.status==401)
  {
  if(error.error.reason=="NOT_AUTHORIZED")
  {
  this.toastr.error('You are not authorized','Error');
  this.logout() ;
  }
  else
  {
  this.toastr.error('Invalid access token','Error');
  
  }
  }
  else if(error.status==403)
  {
  this.toastr.error('You should be logged in','Error');
  //
  }
  else if(error.status==500)
  {
  this.toastr.error('Some Error Occured. Please try after sometime','Error');
  //
  }
  else if(error.status==404)
  {
  this.toastr.error('Some Error Occured. Please contact administrator','Error');
  //
  }
  });
  }
  else
  {this.http.put(environment.projectUrl+ route, data).subscribe(
  (data:any) =>{
  return data;
  },
  (error:any) =>{
  console.log("ERROR", error);
  if(error.status==401)
  {
  if(error.error.reason=="NOT_AUTHORIZED")
  {
  this.toastr.error('You are not authorized','Error');
  this.logout() ;
  }
  else
  {
  this.toastr.error('Invalid access token','Error');
  
  }
  }
  else if(error.status==403)
  {
  this.toastr.error('You should be logged in','Error');
  //
  }
  else if(error.status==500)
  {
  this.toastr.error('Some Error Occured. Please try after sometime','Error');
  //
  }
  else if(error.status==404)
  {
  this.toastr.error('Some Error Occured. Please contact administrator','Error');
  //
  }
  });}
  }
  getData(isSecured,route, data) {
  if(isSecured)
  {return this.http.get(environment.projectUrl+ route ,
  {params:Object.getOwnPropertyNames(data)
  .reduce((p, key) => p.set(key, data[key]), new HttpParams())}
  
  ).toPromise().then(
  (data:any) =>{
  return data;
  },
  (error:any) =>{
  
  console.log("ERROR", error);
  if(error.status==401)
  {
  if(error.error.reason=="NOT_AUTHORIZED")
  {
  this.toastr.error('You are not authorized','Error');
  this.logout() ;
  }
  else
  {
  this.toastr.error('Invalid access token','Error');
  
  }
  }
  else if(error.status==403)
  {
  this.toastr.error('You should be logged in','Error');
  //
  }
  else if(error.status==500)
  {
  this.toastr.error('Some Error Occured. Please try after sometime','Error');
  //
  }
  else if(error.status==404)
  {
  this.toastr.error('Some Error Occured. Please contact administrator','Error');
  //
  }
  });
  }
  else
  {this.http.post(environment.projectUrl+ route, data).subscribe(
  (data:any) =>{
  return data;
  },
  (error:any) =>{
  console.log("ERROR", error);
  if(error.status==401)
  {
  if(error.error.reason=="NOT_AUTHORIZED")
  {
  this.toastr.error('You are not authorized','Error');
  this.logout() ;
  }
  else
  {
  this.toastr.error('Invalid access token','Error');
  
  }
  }
  else if(error.status==403)
  {
  this.toastr.error('You should be logged in','Error');
  //
  }
  else if(error.status==500)
  {
  this.toastr.error('Some Error Occured. Please try after sometime','Error');
  //
  }
  else if(error.status==404)
  {
  this.toastr.error('Some Error Occured. Please contact administrator','Error');
  //
  }
  });}
  }
  async logout() {
  
  this.clearAllCookies();
  localStorage.clear();
  await this.keycloakService.logout();
  };
  clearAllCookies()
  {
  var theCookies = document.cookie.split(';');
  
  for (var i = 1 ; i <= theCookies.length; i++) {
  document.cookie = theCookies[i-1] + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  
  }
  
  }
}
