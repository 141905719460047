import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../service/common.service';
import { $ } from 'protractor';


@Component({
    selector: 'app-tutorial',
    templateUrl: './tutorial.component.html',
    styleUrls: ['./tutorial.component.css']
  })

  export class TutorialComponent implements OnInit {

    contextRoot:string=environment.contextRoot;
    ngOnInit(): void {
        
    
    }
    closeiFrame(podcastFrame:string){

      var src = document.getElementById(podcastFrame).getAttribute('src');
      console.log(src);
      document.getElementById(podcastFrame).setAttribute("src",'');
      document.getElementById(podcastFrame).setAttribute("src",src);
    //   // $("podcastFrame").attr('src','');
    //   // $("podcastFrame").attr('src',src);

     }
  }

  
