import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormBuilder, Validators } from '@angular/forms';
import { CommonService } from '../service/common.service';
import { KeycloakService } from 'keycloak-angular';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  contextRoot:string=environment.contextRoot;
  clientCode:string=environment.clientCode;
  userForm:any;
  submitted :boolean= false;
  token:any;
  

  constructor(private formBuilder:FormBuilder,
    private commonService:CommonService,
    private keycloakService:KeycloakService,
    private toastr: ToastrService) { }

  async ngOnInit(){
    this.userForm = this.formBuilder.group({
      'newPassword': ['',Validators.required],
      'confirmNewPassword':['',Validators.required]
    });
    

  }

async updateUser(userForm:any)
{
  this.submitted = true;
  // stop here if form is invalid
  if (this.userForm.invalid) {
    this.toastr.error('Invalid form entries','Error');
    return;
   }
   if(userForm.newPassword!=userForm.confirmNewPassword)
   {
    this.toastr.error('Please enter Same new passsword and confirm new password','Error');
    return;
   }
   let username:string = await this.keycloakService.getUsername();
   this.commonService.postData(true,  this.clientCode+'/users/changePassword/'+username+'/'+userForm.confirmNewPassword,{})
     .then((response:any)=>{
       if(response.success)
       {
        this.toastr.success("Password Changed Successfully", 'Success');
        this.submitted = false;
        this.userForm.reset();
       }
       else
       {
        this.toastr.error("Some thing went wrong", 'Error');
       }
     
 });
}

}
