<!-- page content -->
    <div class="page-content">
      <div class="page-title">
          <div class="title_left">
              <h3>Queries</h3>
          </div>
          <div class="title_right">
              <div class="col-md-5 col-sm-5 col-xs-12 form-group pull-right top_search">
                <form  (ngSubmit)="getSearchResult()">  
                  <div class="input-group">
                      <input type="text" class="form-control" [(ngModel)]="searchCriteria.subject" name="subject" placeholder="Query Search...">
                      <span class="input-group-btn">
              <button class="btn btn-default submit-btn" type="Submit">Go!</button>
          </span>
                  </div>
                  </form>
              </div>
          </div>
      </div>
      <div class="clearfix"></div>
      <div class="row">
        <div class="col-md-12">
          <div class="x_panel">
            <div class="x_title">
              <h2>Queries</h2>
              <a routerLink="/{{contextRoot}}/query" routerLinkActive="active" class="btn btn-default submit-btn pull-right"> <i class="fa fa-plus"></i> Add New Query</a>
              <div class="clearfix"></div>
            </div>
            <div class="x_content">
            <!-- start project list -->
            <div class="table-responsive">
            <table class="table table-striped projects table-res" matSort (matSortChange)="sortData($event)">
              <thead>
                <tr>
                  <th mat-sort-header="id">Query ID</th>
                  <th mat-sort-header="clientId" *ngIf="isSmithAdmin">Organization</th>
                  <th mat-sort-header="subject" class="w20">Subject</th>
                  <th mat-sort-header="documentCategory" >Document Category</th>
                  <!-- <th mat-sort-header="ticketType">Ticket Type</th> -->
                  <th mat-sort-header="assignee">Assignee</th>
                  <th mat-sort-header="etd">ETD</th>                  
                  <th mat-sort-header="createdByName">Created By</th>
                  <th mat-sort-header="status">Status</th>
                  <th class="w20">Actions</th>
                </tr>
              </thead>
              <tbody >
                <tr *ngFor="let query of pageOfItems">
                  <td>{{query.id}}</td>
                  <td *ngIf="isSmithAdmin">{{clientMap.get(query.clientId)}}</td>
                  <td>{{query.subject}}</td>
                  <td>{{query.documentCategory}}</td>
                  <!-- <td>{{query.ticketType}}</td> -->
                  <td>{{asssigneeMap.get(query.assignee)}}</td>
                  <td>{{query.etd | date: 'dd-MM-yyyy'}}</td>
                  <td>{{query.createdByName}}</td>
                  <td>{{query.status}}</td>
                  <td>
                    <button  (click)="viewQuery(query.id)" class="btn btn-primary btn-xs"><i class="fa fa-folder"></i> View </button>
                    <button (click)="editQuery(query.id)" class="btn btn-info btn-xs"><i class="fa fa-pencil"></i> Edit </button>
                    <button *ngIf="query.status == 'Draft'" (click)="deleteQuery(query.id)" class="btn btn-info btn-xs"><i class="fa fa-trash"></i> Delete </button>
                    <!-- <a href="queries.html" class="btn btn-danger btn-xs"><i class="fa fa-trash-o"></i> Delete </a> -->
                  </td>
                </tr>
                 </tbody>
            </table>
          </div>
            <!-- end project list -->
            <jw-pagination [items]="sortedData" (changePage)="onChangePage($event)"></jw-pagination>
            </div>
          </div>
        </div>
      </div>
      
      
  <!-- /page content -->
 
  