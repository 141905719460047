import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../service/common.service';
import { GlobalService } from '../service/global.service';
import { Sort } from '@angular/material/sort';

export interface Feedback {
  id: number;
  subject: number;
  feedbackType: string;
}

@Component({
  selector: 'app-feedback-search',
  templateUrl: './feedback-search.component.html',
  styleUrls: ['./feedback-search.component.css']
})
export class FeedbackSearchComponent implements OnInit {
  feedbacks:Feedback[];
  clients:any=[];
  clientMap:Map<string,string>=new Map();
  isSmithAdmin:boolean=null;
  clientCode:string="";
  searchCriteria:any={};
  contextRoot:string=environment.contextRoot;
  sortedData: Feedback[]; 
  
  constructor( private router: Router,
    private route:ActivatedRoute,
    private commonService: CommonService,
    private globalService: GlobalService
    ) { this.clientCode=environment.clientCode;
    this.getIsAdmin();
    this.commonService.getData(true,  this.clientCode+'/feedbackRest/?firstResult=0&maxResult=10', {})
    .then((response:any)=>{
    this.feedbacks= response;
    console.log(this.feedbacks);
    this.sortedData = this.feedbacks.slice();
    });
    this.getClientMap();
    }

  getClientMap()
  {
    this.commonService.getData(true,this.clientCode+'/client/?firstResult=-1&maxResult=0', {}).then(response =>{
      this.clients = response;
      for(let i=0; i<this.clients.length;i++ ){
        this.clientMap.set(this.clients[i].id,this.clients[i].name)

      }
      console.log(this.clientMap)
    })    
  }
  async getIsAdmin(){
    let response = await  this.globalService.callfucntion();
    this.isSmithAdmin=response[0].admin;
  }
  
 getSearchResult()
 {
  this.commonService.getData(true,  this.clientCode+'/feedbackRest/?firstResult=0&maxResult=10', this.searchCriteria)
  .then((response:any)=>{
  this.feedbacks= response;
  console.log(this.feedbacks);
  this.sortedData = this.feedbacks.slice();
  });
 }
 viewFeedback(feedbackId:string){
  this.router.navigate(['/'+environment.contextRoot+'/feedback/' + feedbackId + '/view']);
}
  ngOnInit(): void {
  }
  sortData(sort: Sort) {
    const data = this.feedbacks.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }
  
    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'id': return compare(a.id, b.id, isAsc);
        case 'subject': return compare(a.subject, b.subject, isAsc);
        case 'feedbackType': return compare(a.feedbackType, b.feedbackType, isAsc);
        default: return 0;
      }
    });
  }
  }
  function compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  
}
