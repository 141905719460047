import { Injectable } from '@angular/core';
import {
  CalendarSchedulerEvent,
  CalendarSchedulerEventStatus,
  CalendarSchedulerEventAction
} from 'angular-calendar-scheduler';
import {
  addDays,
  startOfHour,
  addHours,
  subHours,
  setHours,
  subMinutes,
  addMinutes
} from 'date-fns';
@Injectable({
  providedIn: 'root'
})
export class CalendarService { 
  
  getEvents(actions: CalendarSchedulerEventAction[]): Promise<CalendarSchedulerEvent[]> {
  const events = [
      <CalendarSchedulerEvent>{
          id: '1',
          start: addDays(startOfHour(new Date()), 1),
          end: addDays(addHours(startOfHour(new Date()), 1), 1),
          title: 'Event 1',
          content: 'IMPORTANT EVENT',
          color: { primary: '#E0E0E0', secondary: '#EEEEEE' },
          actions: actions,
          status: 'danger' as CalendarSchedulerEventStatus,
          isClickable: true,
          isDisabled: false,
          draggable: true,
          resizable: {
              beforeStart: true,
              afterEnd: true
          }
      }
      
  ];

  return new Promise(resolve => setTimeout(() => resolve(events), 3000));
}
}
