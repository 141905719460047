      <!-- page content -->
        <div class="page-content">
          <div class="page-title">
            <div class="title_left">
              <h3>Home</h3>
            </div>
          </div>
          <div class="clearfix"></div>
          <div class="top_tiles">
            <div class="animated flipInY col-lg-3 col-md-3 col-sm-6 col-xs-12">
              <div class="tile-stats">
                  <div class="icon" ><i  class="fa fa-question-circle"></i>
                  </div>
                  <div class="count">{{queryCount}}</div>
                  <h3><a routerLink="/{{contextRoot}}/queries" routerLinkActive="active">Queries</a></h3>
                  <!-- <p>Lorem ipsum psdea itgum rixt.</p> -->
              </div>
          </div>
          <div class="animated flipInY col-lg-3 col-md-3 col-sm-6 col-xs-12">
            <div class="tile-stats">
                <div class="icon"><i class="fa fa-calendar"></i>
                </div>
                <div class="count">{{appointmentCount}}</div>
                <h3><a routerLink="/{{contextRoot}}/listAppointments" routerLinkActive="active">Appointment</a></h3>
                <!-- <p>Lorem ipsum psdea itgum rixt.</p> -->
            </div>
        </div>
            <div  [appPermission]="'DASHBOARD_CONTRACT_MANAGEMENT'" class="animated flipInY col-lg-3 col-md-3 col-sm-6 col-xs-12">
              <div class="tile-stats">
                <div class="icon"><i class="fa fa-file-text"></i>
                </div>
                <div class="count">{{documentCount}}</div>
                <h3><a routerLink="/{{contextRoot}}/documents" routerLinkActive="active">Contract Management</a></h3>
                <!-- <p>Lorem ipsum psdea itgum rixt.</p> -->
              </div>
            </div>
            <div [appPermission]="'DASHBOARD_FEEDBACK'" class="animated flipInY col-lg-3 col-md-3 col-sm-6 col-xs-12">
              <div class="tile-stats">
                  <div class="icon"><i class="fa fa-comments"></i>
                  </div>
                  <div class="count">{{feedbackCount}}</div>
                  <h3><a routerLink="/{{contextRoot}}/feedbackSearch" routerLinkActive="active">Feedback</a></h3>
                  <!-- <p>Lorem ipsum psdea itgum rixt.</p> -->
              </div>
          </div>
              
          <div class="animated flipInY col-lg-3 col-md-3 col-sm-6 col-xs-12">
            <div class="tile-stats">
                <div class="icon" ><i  class="fa fa-book"></i>
                </div>
                <div class="count">1</div>
                <h3><a routerLink="/{{contextRoot}}/km" routerLinkActive="active">KNITBook</a></h3>
                <!-- <p>Lorem ipsum psdea itgum rixt.</p> -->
            </div>
        </div>
        <div class="animated flipInY col-lg-3 col-md-3 col-sm-6 col-xs-12">
          <div class="tile-stats">
              <div class="icon" ><i  class="fa fa-bullhorn"></i>
              </div>
              <div class="count">5</div>
              <h3><a routerLink="/{{contextRoot}}/tutorial" routerLinkActive="active">KNITTelly</a></h3>
              <!-- <p>Lorem ipsum psdea itgum rixt.</p> -->
          </div>
      </div>
       
            <div [appPermission]="'DASHBOARD_TIMESHEET'" class="animated flipInY col-lg-3 col-md-3 col-sm-6 col-xs-12">
              <div class="tile-stats">
                  <div class="icon"><i class="fa fa-clock-o"></i>
                  </div>
                  <div class="count">10</div>
                  <h3><a routerLink="/{{contextRoot}}/timesheetSearch" routerLinkActive="active">Timesheet</a></h3>
                  <!-- <p>Lorem ipsum psdea itgum rixt.</p> -->
              </div>
          </div>
                       
           </div>
        </div>
      <!-- /page content -->
