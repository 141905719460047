import { Component, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CommonService } from '../service/common.service';
import { GlobalService } from '../service/global.service';

export interface Appointment {
  id: number;
  clientId: number;
  title: string;
  createdBy: string;
  appointmentTo:string;
  startDate: string;
  endDate: string;
  hangoutLink: string;
}

@Component({
  selector: 'app-list-appointments',
  templateUrl: './list-appointments.component.html',
  styleUrls: ['./list-appointments.component.css']
})


export class ListAppointmentsComponent implements OnInit {
  appointments:Appointment[];
  pageOfItems: Array<any>;
  clients:any=[];
  clientMap:Map<string,string>=new Map();
  isSmithAdmin:boolean=null;
  clientCode:string="";
  searchCriteria:any={};
  contextRoot:string=environment.contextRoot;
  sortedData: Appointment[];
  assignees:any=[];
  asssigneeMap:Map<string,string>=new Map();
 
  ngOnInit(): void {
    
  }
  constructor(
    private router: Router,
    private route:ActivatedRoute,
    private commonService: CommonService,
    private globalService: GlobalService
    ) {
    this.clientCode= environment.clientCode;
    this.getIsAdmin();
    this.commonService.getData(true,  this.clientCode+'/appointmentRest/getUpcomingAppointments/?firstResult=0&maxResult=10', {})
    .then((response:any)=>{
    this.appointments= response;
    console.log(this.appointments);
    this.sortedData = this.appointments.slice();
    });
  }

  async getIsAdmin(){
    let response = await  this.globalService.callfucntion();
    this.isSmithAdmin=response[0].admin;
  }


getSearchResult()
{
  this.commonService.getData(true,  this.clientCode+'/appointmentRest/getUpcomingAppointments/?firstResult=0&maxResult=10', this.searchCriteria)
  .then((response:any)=>{
  this.appointments= response;
  console.log(this.appointments);
  this.sortedData = this.appointments.slice();
  });
}

sortData(sort: Sort) {
  const data = this.appointments.slice();
  if (!sort.active || sort.direction === '') {
    this.sortedData = data;
    return;
  }

  this.sortedData = data.sort((a, b) => {
    const isAsc = sort.direction === 'asc';
    switch (sort.active) {
      case 'id': return compare(a.id, b.id, isAsc);
      case 'clientId': return compare(a.clientId, b.clientId, isAsc);
      case 'title': return compare(a.title, b.title, isAsc);
      case 'createdBy': return compare(a.createdBy, b.createdBy, isAsc);
      case 'startDate': return compare(a.startDate, b.startDate, isAsc);
      case 'endDate': return compare(a.endDate, b.endDate, isAsc);
      case 'hangoutLink': return compare(a.hangoutLink,b.hangoutLink,isAsc)
      default: return 0;
    }
  });
}
onChangePage(pageOfItems: Array<any>) {
  // update current page of items
  this.pageOfItems = pageOfItems;
}

}
function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
