<div class="page-content">
    <div class="page-title">
      <div class="title_left">
        <h3>Billing</h3>
      </div>
    </div>
    <div class="clearfix"></div>
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="x_panel">
          <div class="x_content">
              <form [formGroup]="billingForm" (ngSubmit)="getBilling(billingForm.value)"class="form-horizontal form-label-left">
                <div *ngIf="isSmithAdmin" class="form-group col-md-6 col-xs-12">
                    <label class="control-label col-md-3 col-sm-3 col-xs-12">Organization*</label>
                    <div class="col-md-9 col-sm-9 col-xs-12">
                        <select class="form-control" formControlName="clientId" name="clientId" [ngClass]="{ 'is-invalid': submitted &&  billingForm.controls.clientId.errors }" >
                            <option [value]="''">Choose option</option>
                            <option *ngFor="let client of clients"  [value]="client.id">{{client.name}}</option>
                        </select>
                        <div *ngIf="(billingForm.controls.clientId.touched && billingForm.controls.clientId.invalid) || (submitted && billingForm.controls.clientId.errors)" class="red">
                            Organization is required.
                          </div>  
                    </div>
                </div> 

                <div class="form-group col-md-6 col-xs-12">
                    <label class="control-label col-md-3 col-sm-3 col-xs-12">Month</label>
                    <div class="col-md-9 col-sm-9 col-xs-12">
                        <input type="text" class="form-control" id="month" formControlName="month" placeholder="Month" >
                    </div>
                </div> 

                  <div class="row">
                    <div class="form-group col-md-12 col-xs-12 text-right">
                        <div class="col-md-9 col-sm-9 col-xs-12 col-md-offset-3">
                            <button type="button" routerLink="/{{contextRoot}}/dashboard" routerLinkActive="active"  class="btn btn-primary">Cancel</button>
                            <button type="Submit" class="btn btn-default submit-btn">Search</button>
                        </div>
                    </div>
                    </div>
                  </form>              
          </div>
        </div>
      </div>
    </div>
</div>
