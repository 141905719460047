import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { CommonService } from '../service/common.service';
import { GlobalService } from '../service/global.service';
import { UploadfileService } from '../service/uploadfile.service';

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.css']
})
export class BillingComponent implements OnInit {
  contextRoot:string=environment.contextRoot;
  clientCode:string="";
  billingForm:any;
  masterData:any={};  
  clients:any=[];
  submitted :boolean= false;
  isSmithAdmin:boolean=null;
  clientId:any="";

  constructor(private formBuilder: FormBuilder, 
    private toastr: ToastrService,
    private commonService:CommonService,
    private route:ActivatedRoute, 
    private router: Router,
    private uploadService: UploadfileService,
    private globalService: GlobalService) { 
      this.clientCode= environment.clientCode;
      this.getIsAdmin();
    }
    async getIsAdmin(){
      let response = await  this.globalService.callfucntion();
      this.isSmithAdmin=response[0].admin;
      if(!this.isSmithAdmin)
      {
        this.clientId=response[0].id;
        this.billingForm.patchValue({'clientId':response[0].id});
      }
      }
 async ngOnInit() {
   
  this.billingForm = this.formBuilder.group({
    'clientId': [ this.isSmithAdmin?'':this.clientId,Validators.required],
    'month':['',Validators.required],
  });
 this.getClients();
  }


  getClients()
  {
    this.commonService.getData(true,this.clientCode+'/client/?firstResult=-1&maxResult=0', {}).then(response =>{
      this.clients =response;
    })    
  }


  
  
  getBilling(billingData:any)
  {
    this.submitted = true;
    // stop here if form is invalid
    if (this.billingForm.invalid) {
      this.toastr.error('Invalid form entries','Error');
      return;
          }


  
  this.commonService.getData(true,this.clientCode+'/billingRest/', billingData).then( response => {
    if (response.success){
    this.submitted = false;
  }
    else {
    this.toastr.error(response.messages[0].message, 'Error');
    }
    });
    
   

}


getUpcomingMonths(){
let currentDate = new Date();
currentDate.getMonth()
}







}
