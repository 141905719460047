import { Time } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { CommonService } from '../service/common.service';
import { GlobalService } from '../service/global.service';

export interface Timesheet {
  date: string;
  clientId: number;
  query: string;
  startTime: string;
  endTime:string;
  hours: string;
}

@Component({
  selector: 'app-timesheet-search',
  templateUrl: './timesheet-search.component.html',
  styleUrls: ['./timesheet-search.component.css']
})
export class TimesheetSearchComponent implements OnInit {
  timesheets:Timesheet[];
  clients:any=[];
  pageOfItems: Array<any>;
  clientMap:Map<string,string>=new Map();
  isSmithAdmin:boolean=null;
  clientCode:string="";
  searchCriteria:any={};
  contextRoot:string=environment.contextRoot;
  sortedData: Timesheet[]; 
  
  constructor( private router: Router,
    private route:ActivatedRoute,
    private toastr: ToastrService,
    private commonService: CommonService,
    private globalService: GlobalService
    ) { this.clientCode=environment.clientCode;
    this.getIsAdmin();
    this.commonService.getData(true,  this.clientCode+'/timesheetRest/?firstResult=-1&maxResult=0', {})
    .then((response:any)=>{
    this.timesheets= response;
    console.log(this.timesheets);
    this.sortedData = this.timesheets.slice();
    });
    this.getClientMap();
    }

  getClientMap()
  {
    this.commonService.getData(true,this.clientCode+'/client/?firstResult=-1&maxResult=0', {}).then(response =>{
      this.clients = response;
      for(let i=0; i<this.clients.length;i++ ){
        this.clientMap.set(this.clients[i].id,this.clients[i].name)

      }
      console.log(this.clientMap)
    })    
  }
  async getIsAdmin(){
    let response = await  this.globalService.callfucntion();
    this.isSmithAdmin=response[0].admin;
  }
  editTimesheet(timesheetId:string){
     this.router.navigate(['/'+environment.contextRoot+'/timesheet/' + timesheetId + '/edit']);
  }
  deleteTimesheet(timesheetId:string){
    this.commonService.postData(true,  this.clientCode+'/timesheetRest/deleteTimesheet/'+timesheetId, {})
    .then((response:any)=>{
    if(response.success)
   {
    this.toastr.success("Timesheet Deleted Successfully", 'Success');
   this. getSearchResult();
   }
    });
 }
 getSearchResult()
 {
  this.commonService.getData(true,  this.clientCode+'/timesheetRest/?firstResult=-1&maxResult=0', this.searchCriteria)
  .then((response:any)=>{
  this.timesheets= response;
  console.log(this.timesheets);
  this.sortedData = this.timesheets.slice();
  });
 }
  ngOnInit(): void {
  }
  sortData(sort: Sort) {
    const data = this.timesheets.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }
  
    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'date': return compare(a.date, b.date, isAsc);
        case 'clientId': return compare(a.clientId, b.clientId, isAsc);
        case 'query': return compare(a.query, b.query, isAsc);
        case 'startTime': return compare(a.startTime, b.startTime, isAsc);
        case 'endTime': return compare(a.endTime, b.endTime, isAsc);
        case 'hours': return compare(a.hours, b.hours, isAsc);
        default: return 0;
      }
    });
  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }
  


  }
  function compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
